import logo from "../../assets/images/logo-kipra/kipra-low-resolution-logo-color-on-transparent-background.png";
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

function DropDown() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);

  const profileRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [profileRef]);

  return (
    <li className="dropdown notification-list">
      <button
        onClick={toggleDropdown}
        style={{ background: "none", border: "none", padding: 0 }}
        ref={profileRef}
      >
        <i
          className="uil uil-user-circle noti-icon"
          style={{ color: "#98a6ad" }}
        ></i>
      </button>
      {isOpen && (
        <div
          className="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown show"
          aria-labelledby="topbar-userdrop"
          style={{
            position: "absolute",
            inset: "0px auto auto 0px",
            margin: "0px",
            transform: "translated(185px, 72px, 0px)",
          }}
        >
          {/* item */}
          <div className="dropdown-header noti-title">
            <h6 className="text-overflow m-0">KIPRA LITE</h6>
          </div>

          <a
            href="https://kipra.ai/#contactUs"
            className="dropdown-item notify-item"
          >
            <i className="mdi mdi-email-outline me-1"></i>
            <span>Contact us</span>
          </a>
        </div>
      )}
    </li>
  );
}

function NavParts(props) {
  const [isDevice, setDevice] = useState(false);
  const toggleDevice = () => setDevice(!isDevice);
  const deviceRef = useRef(null);

  let deviceColor = "#8a949f";
  if (props.currPage === "device") {
    deviceColor = "#3688fc";
  }

  const [isResource, setResource] = useState(false);
  const toggleResource = () => setResource(!isResource);
  const resourceRef = useRef(null);

  let resourceColor = "#8a949f";
  if (props.currPage === "resource") {
    resourceColor = "#3688fc";
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (deviceRef.current && !deviceRef.current.contains(event.target)) {
        setDevice(false);
      }
      if (resourceRef.current && !resourceRef.current.contains(event.target)) {
        setResource(false);
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [deviceRef, resourceRef]);

  return (
    <ul className="navbar-nav large-screen-margin-left-bot">
      <li className="nav-item dropdown">
        <button
          className="nav-link dropdown-toggle arrow-none"
          id="topnav-layouts"
          onClick={toggleDevice}
          aria-haspopup="true"
          aria-expanded={isDevice}
          style={{
            background: "none",
            border: "none",
          }}
          ref={deviceRef}
        >
          <i
            className="mdi mdi-shield-check me-1"
            style={{ color: deviceColor }}
          ></i>
          <span style={{ color: deviceColor }}>Medical Device</span>
          <div
            className="arrow-down arrow-margin-nav"
            style={{ color: deviceColor }}
          ></div>
        </button>
        <div
          className={`dropdown-menu ${!isDevice ? "" : "show"}`}
          aria-labelledby="topnav-layouts"
        >
          <a
            href="/p/device/USFDA/PMA/"
            className={`dropdown-item ${
              props.currUse === "PMA" ? "active" : ""
            }`}
          >
            PMA
          </a>
          <a
            href="/p/device/USFDA/denovo/"
            className={`dropdown-item ${
              props.currUse === "denovo" ? "active" : ""
            }`}
          >
            De Novo
          </a>
          <a
            href="/p/device/USFDA/510k/"
            className={`dropdown-item ${
              props.currUse === "510k" ? "active" : ""
            }`}
          >
            510(k)
          </a>
        </div>
      </li>
      <li className="nav-item dropdown">
        <button
          className="nav-link dropdown-toggle arrow-none"
          id="topnav-layouts"
          onClick={toggleResource}
          aria-haspopup="true"
          aria-expanded={isResource}
          style={{
            background: "none",
            border: "none",
          }}
          ref={resourceRef}
        >
          <i
            className="uil uil-book-open me-1"
            style={{ color: resourceColor }}
          ></i>
          <span style={{ color: resourceColor }}>Resources</span>
          <div
            className="arrow-down arrow-margin-nav"
            style={{ color: resourceColor }}
          ></div>
        </button>
        <div
          className={`dropdown-menu ${!isResource ? "" : "show"}`}
          aria-labelledby="topnav-layouts"
        >
          <a
            href="/p/resource/USFDA/guidance/"
            className={`dropdown-item ${
              props.currUse === "guidance" ? "active" : ""
            }`}
          >
            FDA Guidance Documents
          </a>
        </div>
      </li>
    </ul>
  );
}

function Navbar() {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  const location = useLocation();
  const pathSegments = location.pathname.split("/").filter((seg) => seg !== "");

  return (
    <div>
      <div className="navbar-custom topnav-navbar topnav-navbar-dark">
        <div className="container-fluid">
          <button
            className={`navbar-toggle ${isNavCollapsed ? "collapsed" : "open"}`}
            onClick={handleNavCollapse}
          >
            <div className="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </button>
          <a href="https://kipra.ai" className="topnav-logo">
            <span className="topnav-logo-lg text-muted d-none d-lg-inline-block large-screen-margin-left-top">
              <img src={logo} alt="" height="40" className="me-2" />
            </span>
            <span className="topnav-logo-sm">
              <img src={logo} alt="" height="40" />
            </span>
          </a>
          <div className="col-3 col-md-3 col-lg-3 float-end">
            <ul className="list-unstyled topbar-menu float-end mb-0 large-screen-margin-right">
              <DropDown />
            </ul>
          </div>
        </div>
      </div>
      <div className="topnav shadow-sm">
        <div className="container-fluid">
          <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
            <div
              className={`collapse navbar-collapse ${
                !isNavCollapsed ? "show" : ""
              }`}
              id="topnav-menu-content"
            >
              <NavParts currPage={pathSegments[1]} currUse={pathSegments[3]} />
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
