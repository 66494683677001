import { useState } from "react";
import Forms from "../../utils/filterBarComponents/Forms.jsx";
import { primaryForm } from "../../utils/filterBarComponents/consts.js";

const guidanceFields = [
  ["guidance_title", "Guidance Title"],
  ["guidance_abstract", "Guidance Summary"],
  ["guidance_pdf_text", "Guidance Full Text"],
  ["fr_abstract", "Federal Register Summary"],
];

function GuidanceFilter(props) {
  const [guidanceFilter, setGuidanceFilter] = useState(false);
  const inGuidance = (field) => {
    return props.filterOptions.fields.includes(field);
  };
  const guidanceUpdate = (field) => {
    if (inGuidance(field)) {
      props.setFilterOptions((prevState) => ({
        ...prevState,
        fields: prevState.fields.filter((item) => item !== field),
      }));
    } else {
      props.setFilterOptions((prevState) => ({
        ...prevState,
        fields: [...prevState.fields, field],
      }));
    }
  };

  return (
    <>
      <div className="card-header">
        <h5>
          <a
            className={"custom-accordion-title d-block py-1"}
            onClick={() => setGuidanceFilter(!guidanceFilter)}
          >
            Search key words within...
            <i
              className={
                "mdi accordion-arrow mdi-chevron-" +
                (guidanceFilter ? "up" : "down")
              }
            />
          </a>
        </h5>
      </div>
      <div className={"collapse-custom" + (guidanceFilter ? " show" : "")}>
        <div className="card-body">
          <Forms
            inFilter={inGuidance}
            filterUpdate={guidanceUpdate}
            fields={guidanceFields}
            type={primaryForm}
          />
        </div>
      </div>
    </>
  );
}

export default GuidanceFilter;
