import { convertDatetime } from "./utils.js";

function TextLink({ linkUrl, title, align }) {
  return (
    <>
      {linkUrl ? (
        <a href={linkUrl} target="_blank" rel="noopener noreferrer">
          <i className={"mdi mdi-file-pdf-outline " + align}></i> {title}
        </a>
      ) : (
        <>
          <i className={"mdi mdi-file-pdf-outline " + align}></i> {title}
        </>
      )}
    </>
  );
}

function SubField({ titleText, dataText }) {
  return (
    <div className="mb-1">
      <p>
        <strong>{titleText}</strong>
      </p>
      <div>{dataText || "N/A"}</div>
    </div>
  );
}

function SubWithField({ titleText, dataText, link }) {
  if (dataText) {
    dataText = dataText.split(" ");
  }
  return (
    <div className="mb-1">
      <p>
        <strong>{titleText}</strong>
      </p>
      <div>
        {dataText && dataText.length > 0
          ? dataText.map((text, index) => (
              <a
                key={index}
                href={link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {index > 0 ? " " + text : text}
              </a>
            ))
          : "N/A"}
      </div>
    </div>
  );
}

function SubFieldLink({ titleText, dataText, buildLink }) {
  if (dataText) {
    dataText = dataText.split(" ");
  }
  return (
    <div className="mb-1">
      <p>
        <strong>{titleText}</strong>
      </p>
      <div>
        {dataText && dataText.length > 0
          ? dataText.map((text, index) => (
              <a
                key={index}
                href={buildLink(text)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {index > 0 ? " " + text : text}
              </a>
            ))
          : "N/A"}
      </div>
    </div>
  );
}

function ReviewTimeline({ date_received, decision_date, num_days_approve }) {
  return (
    <div className="mb-1">
      <p>
        <strong>Review Timeline:</strong>
      </p>
      <div>
        {convertDatetime(date_received)} - {convertDatetime(decision_date)} (
        {num_days_approve} days)
      </div>
    </div>
  );
}

function SubFieldBulletPoint({ titleText, dataText }) {
  // Function to process the dataText and convert semicolon-separated values to bullet points
  const processDataText = (text) => {
    if (text === null) return null; // Return null if dataText is explicitly null

    // Split the string by semicolons and trim whitespace
    const items = text.split(";").map((item) => item.trim());

    // Return an unordered list if there are multiple items
    if (items.length > 0) {
      return (
        <ul style={{ marginBottom: "0" }}>
          {items.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      );
    } else {
      return "N/A";
    }
  };

  return (
    <div className="mb-1">
      <p>
        <strong>{titleText}</strong>
      </p>
      <div>{processDataText(dataText)}</div>
    </div>
  );
}

function OneLine({ titleText, dataText }) {
  return (
    <div className="mb-1">
      <p>
        <strong>{titleText}</strong> {dataText || "N/A"}
      </p>
    </div>
  );
}

function BasicTextLink({ text, link }) {
  return (
    <>
      {link ? (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="mb-1"
        >
          {text}
        </a>
      ) : (
        text
      )}
    </>
  );
}

export {
  TextLink,
  SubField,
  SubWithField,
  SubFieldLink,
  ReviewTimeline,
  SubFieldBulletPoint,
  OneLine,
  BasicTextLink,
};
