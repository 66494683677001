function Features() {
  return (
    <section className="py-4" id="features">
      <div className="container">
        <div className="row py-4">
          <div className="col-lg-12">
            <div className="text-center">
              <h1 className="mt-0">
                <i className="mdi mdi-infinity"></i>
              </h1>

              <h3>
                <span className="text-primary">FDA </span>
                info at your fingertips
              </h3>
              <p className="text-muted mt-2">
                Manual curation is used to ensure data accuracy
                <br />
                However, please visit webpages on FDA.gov for reference
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div className="text-center p-3">
              <div className="avatar-sm m-auto">
                <span className="avatar-title bg-primary-lighten rounded-circle">
                  <i className="uil uil-search-alt text-primary font-24"></i>
                </span>
              </div>
              <h4 className="mt-3">Dual Search Modes</h4>
              <p className="text-muted mt-2 mb-0">
                <span className="text-primary">Google-Like Search </span>
                Results are ordered by relevance.
              </p>
              <p className="mt-1 text-muted">
                <span className="text-primary">Exact Search</span> Fine-tuned
                precise search.
              </p>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="text-center p-3">
              <div className="avatar-sm m-auto">
                <span className="avatar-title bg-primary-lighten rounded-circle">
                  <i className="mdi mdi-information-variant text-primary font-24"></i>
                </span>
              </div>
              <h4 className="mt-3">
                Search <span className="text-primary"> More </span>
              </h4>
              <p className="text-muted mt-2 mb-0">
                Search within guidance summary, intended use, pdf texts, federal
                register summary, and more!
              </p>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="text-center p-3">
              <div className="avatar-sm m-auto">
                <span className="avatar-title bg-primary-lighten rounded-circle">
                  <i className="mdi mdi-sort-alphabetical-ascending text-primary font-24"></i>
                </span>
              </div>
              <h4 className="mt-3">Comprehensive Highlighting</h4>
              <p className="text-muted mt-2 mb-0">
                Rapidly identify relevant information via{" "}
                <span className="text-primary"> highlighted excerpts.</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
