import { useState } from "react";
import Forms from "../../utils/filterBarComponents/Forms.jsx";
import { primaryForm } from "../../utils/filterBarComponents/consts.js";

const centerFields = [
  ["CDRH", "CDRH"],
  ["CBER", "CBER"],
];

function Center(props) {
  const [centerFilter, setCenterFilter] = useState(false);
  const inCenters = (field) => {
    return props.filterOptions.centers.includes(field);
  };
  const centerUpdate = (field) => {
    if (inCenters(field)) {
      props.setFilterOptions((prevState) => ({
        ...prevState,
        centers: prevState.centers.filter((item) => item !== field),
      }));
    } else {
      props.setFilterOptions((prevState) => ({
        ...prevState,
        centers: [...prevState.centers, field],
      }));
    }
  };

  return (
    <>
      <div className="card-header">
        <h5>
          <a
            className={"custom-accordion-title d-block py-1"}
            onClick={() => setCenterFilter(!centerFilter)}
          >
            Center
            <i
              className={
                "mdi accordion-arrow mdi-chevron-" +
                (centerFilter ? "up" : "down")
              }
            />
          </a>
        </h5>
      </div>
      <div className={"collapse-custom" + (centerFilter ? " show" : "")}>
        <div className="card-body">
          <Forms
            inFilter={inCenters}
            filterUpdate={centerUpdate}
            fields={centerFields}
            type={primaryForm}
          />
        </div>
      </div>
    </>
  );
}

export default Center;
