import "../../../assets/css/icons.min.css";
import "../../../assets/css/app-creative.min.css";
import NavBar from "../../components/NavBar";
import DeviceBody from "../../components/resource/ResourceBody";

function Guidance() {
  return (
    <div>
      <NavBar />
      <DeviceBody resourceType={"guidance"} />
    </div>
  );
}

export default Guidance;
