import { convertDatetime } from "../utils/utils.js";

// Data Grid Column Creation
function denovoColumns(callback, setHighlight) {
  const columns = [
    {
      field: "deNovoNumber",
      headerName: "De Novo Number",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <div>
          <a href="#" onClick={() => callback(params.value)}>
            {params.value}
          </a>
        </div>
      ),
    },
    {
      field: "deviceName",
      headerName: "Device Name",
      minWidth: 150,
      flex: 1.5,
      hideable: false,
      renderCell: (params) => (
        <div>
          {params.value}
          {params.row.searchRowData && (
            <div className="mt-1">
              <button
                type="button"
                className="btn rounded-pill btn-outline-success btn-sm"
                onClick={() => setHighlight(params.row.searchRowData)}
              >
                Highlights
              </button>
            </div>
          )}
        </div>
      ),
    },
    {
      field: "deviceClassificationName",
      headerName: "Device Classification",
      minWidth: 150,
      flex: 1.3,
    },
    { field: "applicant", headerName: "Requester", minWidth: 150, flex: 1.3 },
    {
      field: "classificationCommittee",
      headerName: "Classification Committee",
      minWidth: 150,
      flex: 1.3,
    },
    {
      field: "reviewAdvisoryCommittee",
      headerName: "Review Committee",
      minWidth: 150,
      flex: 1.3,
    },
    {
      field: "dateReceived",
      headerName: "Date Received",
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: "decisionDate",
      headerName: "Decision Date",
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: "numDaysApprove",
      headerName: "# Days Approve",
      minWidth: 75,
      flex: 0.5,
    },
    { field: "proCode", headerName: "Procode", minWidth: 100, flex: 0.5 },
    {
      field: "regulationNumber",
      headerName: "Regulation Number",
      minWidth: 100,
      flex: 0.5,
    },
    { field: "decision", headerName: "Decision", minWidth: 100, flex: 0.5 },
    { field: "type", headerName: "Type", minWidth: 100, flex: 0.5 },
  ];
  return columns;
}

function _510kColumns(callback, setHighlight) {
  const columns = [
    {
      field: "_510kNumber",
      headerName: "510(k) Number",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <div>
          <a href="#" onClick={() => callback(params.value)}>
            {params.value}
          </a>
        </div>
      ),
    },
    {
      field: "deviceName",
      headerName: "Device Name",
      minWidth: 150,
      flex: 1.5,
      hideable: false,
      renderCell: (params) => (
        <div>
          {params.value}
          {params.row.searchRowData && (
            <div className="mt-1">
              <button
                type="button"
                className="btn rounded-pill btn-outline-success btn-sm"
                onClick={() => setHighlight(params.row.searchRowData)}
              >
                Highlights
              </button>
            </div>
          )}
        </div>
      ),
    },
    {
      field: "deviceClassificationName",
      headerName: "Device Classification",
      minWidth: 150,
      flex: 1.3,
    },
    { field: "applicant", headerName: "Applicant", minWidth: 150, flex: 1.3 },
    {
      field: "correspondent",
      headerName: "Correspondent",
      minWidth: 150,
      flex: 1.3,
    },
    {
      field: "reviewPanel",
      headerName: "Review Panel",
      minWidth: 150,
      flex: 1.3,
    },
    {
      field: "regulationMedicalSpeciality",
      headerName: "Regulation Medical Speciality",
      minWidth: 150,
      flex: 1.3,
    },
    {
      field: "dateReceived",
      headerName: "Date Received",
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: "decisionDate",
      headerName: "Decision Date",
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: "numDaysApprove",
      headerName: "# Days Approve",
      minWidth: 75,
      flex: 0.5,
    },
    { field: "proCode", headerName: "Procode", minWidth: 100, flex: 0.5 },
    {
      field: "regulationNumber",
      headerName: "Regulation Number",
      minWidth: 100,
      flex: 0.5,
    },
    { field: "decision", headerName: "Decision", minWidth: 100, flex: 0.5 },
    { field: "type", headerName: "Type", minWidth: 100, flex: 0.5 },
    {
      field: "combinationProduct",
      headerName: "Combination Product",
      minWidth: 100,
      flex: 0.5,
    },
    {
      field: "thirdPartyReview",
      headerName: "Third Party Review",
      minWidth: 100,
      flex: 0.5,
    },
  ];
  return columns;
}

function pmaColumns(callback, setHighlight) {
  const columns = [
    {
      field: "fullPmaNumber",
      headerName: "Full PMA Number",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <div>
          <a href="#" onClick={() => callback(params.value)}>
            {params.value}
          </a>
        </div>
      ),
    },
    {
      field: "pmaNumber",
      headerName: "PMA Number",
      minWidth: 150,
      flex: 1.1,
    },
    {
      field: "supplementNumber",
      headerName: "Suppl. Number",
      minWidth: 150,
      flex: 1.1,
    },
    {
      field: "device",
      headerName: "Device Name",
      minWidth: 150,
      flex: 1.5,
      hideable: false,
      renderCell: (params) => (
        <div>
          {params.value}
          {params.row.searchRowData && (
            <div className="mt-1">
              <button
                type="button"
                className="btn rounded-pill btn-outline-success btn-sm"
                onClick={() => setHighlight(params.row.searchRowData)}
              >
                Highlights
              </button>
            </div>
          )}
        </div>
      ),
    },
    {
      field: "supplementType",
      headerName: "Suppl. Type",
      minWidth: 150,
      flex: 1.1,
    },
    {
      field: "genericName",
      headerName: "Generic Name",
      minWidth: 150,
      flex: 1.3,
    },
    { field: "applicant", headerName: "Applicant", minWidth: 150, flex: 1.3 },
    {
      field: "advisoryCommittee",
      headerName: "Advisory Committee",
      minWidth: 150,
      flex: 1.1,
    },
    {
      field: "dateReceived",
      headerName: "Date Received",
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: "decisionDate",
      headerName: "Decision Date",
      minWidth: 150,
      flex: 0.5,
    },
    {
      field: "numDaysApprove",
      headerName: "# Days Approve",
      minWidth: 75,
      flex: 0.5,
    },
    { field: "proCode", headerName: "Procode", minWidth: 100, flex: 0.5 },
    {
      field: "regulationNumber",
      headerName: "Regulation Number",
      minWidth: 100,
      flex: 0.5,
    },
    {
      field: "expeditedReviewGranted",
      headerName: "Expedited Review",
      minWidth: 100,
      flex: 0.5,
    },
    {
      field: "combinationProduct",
      headerName: "Combination Product",
      minWidth: 100,
      flex: 0.5,
    },
  ];
  return columns;
}

export const columnsCreate = (tableName, callback, setHighlight) => {
  if (tableName === "fda_denovo" || tableName === "denovo") {
    return denovoColumns(callback, setHighlight);
  } else if (tableName === "fda_510k" || tableName === "510k") {
    return _510kColumns(callback, setHighlight);
  } else if (tableName === "fda_pma" || tableName === "pma") {
    return pmaColumns(callback, setHighlight);
  }
};

// Data Grid Row Creation
async function denovoRows() {
  const getDenovoData = async () => {
    try {
      const response = await fetch("/api/devices/denovo/recent");
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const denovoData = await getDenovoData();
  let rows = [];
  denovoData.forEach((denovo) => {
    const currRow = {
      id: denovo.denovo_num,
      deNovoNumber: denovo.denovo_num,
      deviceName: denovo.device_name,
      applicant: denovo.requester,
      classificationCommittee: denovo.classification_advisory_committee,
      reviewAdvisoryCommittee: denovo.review_advisory_committee,
      dateReceived: convertDatetime(denovo.date_received),
      decisionDate: convertDatetime(denovo.decision_date),
      numDaysApprove: denovo.num_days_approve,
      proCode: denovo.classification_product_code,
      regulationNumber: denovo.regulation_number,
      deviceClassificationName: denovo.device_classification_name,
      decision: denovo.decision,
      type: denovo.type,
      searchRowData: null,
    };
    rows.push(currRow);
  });

  return rows;
}

async function _510kRows() {
  const get510kData = async () => {
    try {
      const response = await fetch("/api/devices/510k/recent");
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const _510kData = await get510kData();
  let rows = [];
  _510kData.forEach((_510k) => {
    const currRow = {
      id: _510k.k_num,
      _510kNumber: _510k.k_num,
      deviceName: _510k.device_name,
      applicant: _510k.applicant,
      correspondent: _510k.correspondent,
      reviewPanel: _510k.review_panel || _510k.review_advisory_committee,
      regulationMedicalSpeciality: _510k.regulation_medical_specialty,
      dateReceived: convertDatetime(_510k.date_received),
      decisionDate: convertDatetime(_510k.decision_date),
      numDaysApprove: _510k.num_days_approve,
      proCode: _510k.classification_product_code,
      regulationNumber: _510k.regulation_number,
      deviceClassificationName: _510k.device_classification_name,
      decision: _510k.decision,
      type: _510k.type,
      combinationProduct: _510k.combination_product,
      thirdPartyReview: _510k.reviewed_by_third_party,
      searchRowData: null,
    };
    rows.push(currRow);
  });

  return rows;
}

async function pmaRows() {
  const getPmaData = async () => {
    try {
      const response = await fetch("/api/devices/pma/recent");
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const pmaData = await getPmaData();
  let rows = [];

  const fixFullPmaNumber = (full_pma_num) => {
    full_pma_num.replace(" ", "");
    return full_pma_num.replace("S", "/S");
  };

  pmaData.forEach((pma) => {
    const currRow = {
      id: pma.full_pma_num,
      fullPmaNumber: fixFullPmaNumber(pma.full_pma_num),
      pmaNumber: pma.pma_number,
      supplementNumber: pma.supplement_number,
      device: pma.device,
      genericName: pma.generic_name,
      applicant: pma.applicant,
      advisoryCommittee: pma.advisory_committee,
      dateReceived: convertDatetime(pma.date_received),
      decisionDate: convertDatetime(pma.decision_date),
      numDaysApprove: pma.num_days_approve,
      proCode: pma.product_code,
      regulationNumber: pma.regulation_number || "N/A",
      supplementType: pma.supplement_type || "Original",
      expeditedReviewGranted: pma.expedited_review_granted,
      combinationProduct: pma.combination_product,
      searchRowData: null,
    };
    rows.push(currRow);
  });

  return rows;
}

export const rowsCreate = async (tableName) => {
  if (tableName === "fda_denovo" || tableName === "denovo") {
    return denovoRows();
  } else if (tableName === "fda_510k" || tableName === "510k") {
    return _510kRows();
  } else if (tableName === "fda_pma" || tableName === "pma") {
    return pmaRows();
  }
};
