function Forms({ inFilter, filterUpdate, fields, type }) {
  return fields.map((field) => (
    <div className={"form-check mb-2 form-checkbox-" + type} key={field[0]}>
      <input
        type="checkbox"
        className="form-check-input"
        name={field[0]}
        checked={inFilter(field[0])}
        onChange={() => filterUpdate(field[0])}
      />
      <label className="form-check-label">{field[1]}</label>
    </div>
  ));
}

export default Forms;
