import { useState } from "react";
import Forms from "../../../utils/filterBarComponents/Forms.jsx";
import {
  primaryForm,
  subForm,
} from "../../../utils/filterBarComponents/consts.js";

const deviceFields = [
  ["k_num", "510(k) Number"],
  ["device_name", "Device Name"],
  ["regulation_number", "Regulation Number"],
  ["device_classification_name", "Device Classification Name"],
  ["classification_product_code", "Product Code"],
];

const appFields = [
  ["applicant", "Applicant Company"],
  ["applicant_contact", "Applicant Contact"],
  ["correspondent", "Correspondent Company"],
  ["correspondent_contact", "Correspondent Contact"],
];

const otherDeviceFields = [
  ["summary_statement_iu_indfu", "IU / IFU (2018 - Present)"],
  ["summary_pdf_text", "Summary / Statement"],
  ["decision_summary_pdf_text", "Decision Summary"],
];

function _510kFilter(props) {
  const [keyWordFilter, setKeyWordFilter] = useState(false);

  const inFields = (field) => {
    return props.filterOptions.fields.includes(field);
  };

  const updateFilter = (prevState, field, additionalFields = []) => {
    const fieldsSet = new Set(prevState.fields);
    if (fieldsSet.has(field)) {
      additionalFields.forEach(fieldsSet.delete, fieldsSet);
      fieldsSet.delete(field);
    } else {
      fieldsSet.add(field);
      additionalFields.forEach(fieldsSet.add, fieldsSet);
    }
    return { ...prevState, fields: Array.from(fieldsSet) };
  };

  const fieldMappings = {
    summary_statement_iu_indfu: [
      "decision_summary_iu",
      "decision_summary_indfu",
    ],
    classification_product_code: [
      "subsequent_product_code",
      "secondary_product_code",
    ],
    summary_pdf_text: ["statement_pdf_text"],
  };

  const fieldsFilterUpdate = (field) => {
    const additionalFields = fieldMappings[field] || [];
    props.setFilterOptions((prevState) =>
      updateFilter(prevState, field, additionalFields)
    );
  };

  const masterInFilters = (typeField) => {
    if (typeField === "device") {
      return deviceFields.some((curr) =>
        props.filterOptions.fields.includes(curr[0])
      );
    } else if (typeField === "app") {
      return appFields.some((curr) =>
        props.filterOptions.fields.includes(curr[0])
      );
    }
  };

  const updateFilterOptions = (fieldsToUpdate, checked, optionsKey) => {
    if (checked) {
      const existingFieldsSet = new Set(props.filterOptions[optionsKey]);
      const toAdd = fieldsToUpdate
        .filter(([fieldName]) => !existingFieldsSet.has(fieldName))
        .map(([fieldName]) => fieldName);

      props.setFilterOptions((prevState) => ({
        ...prevState,
        [optionsKey]: [...prevState[optionsKey], ...toAdd],
      }));
    } else {
      const fieldsSet = new Set(fieldsToUpdate.map(([fieldName]) => fieldName));
      const newFields = props.filterOptions[optionsKey].filter(
        (fieldName) => !fieldsSet.has(fieldName)
      );

      props.setFilterOptions((prevState) => ({
        ...prevState,
        [optionsKey]: newFields,
      }));
    }
  };

  const masterFilterUpdate = (checked, typeField) => {
    switch (typeField) {
      case "device":
        updateFilterOptions(deviceFields, checked, "fields");
        break;
      case "app":
        updateFilterOptions(appFields, checked, "fields");
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="card-header">
        <h5>
          <a
            className={"custom-accordion-title d-block py-1"}
            onClick={() => setKeyWordFilter(!keyWordFilter)}
          >
            Search key words within...
            <i
              className={
                "mdi accordion-arrow mdi-chevron-" +
                (keyWordFilter ? "up" : "down")
              }
            />
          </a>
        </h5>
      </div>
      <div className={"collapse-custom" + (keyWordFilter ? " show" : "")}>
        <div className="card-body">
          <div className="form-check form-checkbox-primary mb-2">
            <input
              type="checkbox"
              className="form-check-input"
              checked={masterInFilters("device")}
              onChange={(e) => masterFilterUpdate(e.target.checked, "device")}
            />
            <label className="form-check-label">Device</label>
          </div>
          <Forms
            inFilter={inFields}
            filterUpdate={fieldsFilterUpdate}
            fields={deviceFields}
            type={subForm}
          />
          <div className="form-check form-checkbox-primary mb-2">
            <input
              type="checkbox"
              className="form-check-input"
              checked={masterInFilters("app")}
              onChange={(e) => masterFilterUpdate(e.target.checked, "app")}
            />
            <label className="form-check-label">
              Applicant & Correspondent
            </label>
          </div>
          <Forms
            inFilter={inFields}
            filterUpdate={fieldsFilterUpdate}
            fields={appFields}
            type={subForm}
          />
          <Forms
            inFilter={inFields}
            filterUpdate={fieldsFilterUpdate}
            fields={otherDeviceFields}
            type={primaryForm}
          />
        </div>
      </div>
    </>
  );
}

export default _510kFilter;
