const InformationRow = ({ title, value, highlightValue, additlStyle = "" }) => (
  <div className={`mt-2 ${additlStyle}`}>
    <p>
      <strong>{title}</strong>
    </p>
    <div
      dangerouslySetInnerHTML={{ __html: highlightValue || value || "N/A" }}
    ></div>
  </div>
);

const SourceLink = ({ linkText, link }) => (
  <a href={link} target="_blank" rel="noopener noreferrer">
    <span>
      FDA Source <i className="uil uil-external-link-alt"></i>
    </span>
  </a>
);

const FRSourceLink = ({ linkText, link }) => (
  <a href={link} target="_blank" rel="noopener noreferrer">
    <span>
      Federal Register Source <i className="uil uil-external-link-alt"></i>
    </span>
  </a>
);

const HighlightText = ({ type, highlights }) => (
  <div>
    {highlights[type].map((element, index) => (
      <div
        key={index}
        className="mt-2"
        dangerouslySetInnerHTML={{ __html: element }}
      />
    ))}
  </div>
);

export { InformationRow, SourceLink, HighlightText, FRSourceLink };
