import { currentYear } from "../utils/utils.js";
import dayjs from "dayjs";

const guidanceOptions = [
  "guidance_title",
  "guidance_abstract",
  "fr_abstract",
  "guidance_pdf_text",
];

const regulatedProductsOptions = [
  "Animal & Veterinary",
  "Biologics",
  "Cosmetics",
  "Dietary Supplements",
  "Drugs",
  "Food & Beverages",
  "Medical Devices",
  "Radiation-Emitting Products",
  "Tobacco",
];

const fdaOrganizationOptions = [
  "Center for Biologics Evaluation and Research",
  "Center for Devices and Radiological Health",
  "Center for Drug Evaluation and Research",
  "Center for Food Safety and Applied Nutrition",
  "Center for Tobacco Products",
  "Center for Veterinary Medicine",
  "National Center for Toxicological Research",
  "Office of Regulatory Affairs",
  "Office of the Commissioner",
  "Oncology Center of Excellence",
];

const guidanceStatus = ["Final", "Draft"];

const initialFlag = 0;

export const getFilterOptions = (resourceType) => {
  if (resourceType === "fda_guidance" || resourceType === "guidance") {
    return {
      index: "fda_guidance",
      search_phrase: null,
      fields: guidanceOptions,
      issue_start_date: dayjs("1975-01-01"),
      issue_end_date: dayjs(`${currentYear}-12-31`),
      fda_organization: fdaOrganizationOptions,
      regulated_products: regulatedProductsOptions,
      guidance_status: guidanceStatus,
      size: 200,
      exact_flag: initialFlag,
    };
  }
};
