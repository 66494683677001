import { useState } from "react";
import Forms from "./Forms.jsx";
import { subForm } from "./consts.js";

function OptionsFilter({
  filterOptions,
  setFilterOptions,
  title,
  options,
  optionsType,
}) {
  const [optionsFilter, setOptionsFilter] = useState(false);

  const inOptions = (field) => {
    return filterOptions[optionsType].includes(field);
  };

  const optionsFilterUpdate = (field) => {
    if (inOptions(field)) {
      setFilterOptions((prevState) => ({
        ...prevState,
        [optionsType]: prevState[optionsType].filter((item) => item !== field),
      }));
    } else {
      setFilterOptions((prevState) => ({
        ...prevState,
        [optionsType]: [...prevState[optionsType], field],
      }));
    }
  };

  const masterInFilters = (typeField) => {
    if (typeField === optionsType) {
      return options.some((curr) =>
        filterOptions[optionsType].includes(curr[0])
      );
    } else {
      return false;
    }
  };

  const updateFilterOptions = (fieldsToUpdate, checked, optionsKey) => {
    if (checked) {
      const existingFieldsSet = new Set(filterOptions[optionsKey]);
      const toAdd = fieldsToUpdate
        .filter(([fieldName]) => !existingFieldsSet.has(fieldName))
        .map(([fieldName]) => fieldName);

      setFilterOptions((prevState) => ({
        ...prevState,
        [optionsKey]: [...prevState[optionsKey], ...toAdd],
      }));
    } else {
      const fieldsSet = new Set(fieldsToUpdate.map(([fieldName]) => fieldName));
      const newFields = filterOptions[optionsKey].filter(
        (fieldName) => !fieldsSet.has(fieldName)
      );

      setFilterOptions((prevState) => ({
        ...prevState,
        [optionsKey]: newFields,
      }));
    }
  };

  const masterFilterUpdate = (checked, typeField) => {
    switch (typeField) {
      case optionsType:
        updateFilterOptions(options, checked, optionsType);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="card-header">
        <h5>
          <a
            className={"custom-accordion-title d-block py-1"}
            onClick={() => setOptionsFilter(!optionsFilter)}
          >
            {title}
            <i
              className={
                "mdi accordion-arrow mdi-chevron-" +
                (optionsFilter ? "up" : "down")
              }
            />
          </a>
        </h5>
      </div>
      <div className={"collapse-custom" + (optionsFilter ? " show" : "")}>
        <div className="card-body">
          <div className="form-check form-checkbox-primary mb-2">
            <input
              type="checkbox"
              className="form-check-input"
              checked={masterInFilters(optionsType)}
              onChange={(e) =>
                masterFilterUpdate(e.target.checked, optionsType)
              }
            />
            <label className="form-check-label">All</label>
          </div>
          <Forms
            inFilter={inOptions}
            filterUpdate={optionsFilterUpdate}
            fields={options}
            type={subForm}
          />
        </div>
      </div>
    </>
  );
}

export default OptionsFilter;
