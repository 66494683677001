import BasicDatePicker from "../../utils/filterBarComponents/DatePicker";
import { useState } from "react";

export default function DateFilter(props) {
  const [dateRecFilter, setDateRecFilter] = useState(false);
  const [dateDecFilter, setDateDecFilter] = useState(false);

  return (
    <>
      <div className="card-header">
        <h5>
          <a
            className={"custom-accordion-title d-block py-1"}
            onClick={() => setDateDecFilter(!dateDecFilter)}
          >
            Decision Date
            <i
              className={
                "mdi accordion-arrow mdi-chevron-" +
                (dateDecFilter ? "up" : "down")
              }
            />
          </a>
        </h5>
      </div>
      <div className={"collapse-custom" + (dateDecFilter ? " show" : "")}>
        <div className="card-body">
          <BasicDatePicker
            label="Start Date"
            currDate={props.filterOptions.dec_start_date}
            setDate={(date) =>
              props.setFilterOptions((prevState) => ({
                ...prevState,
                dec_start_date: date,
              }))
            }
          />
          <BasicDatePicker
            label="End Date"
            currDate={props.filterOptions.dec_end_date}
            setDate={(date) =>
              props.setFilterOptions((prevState) => ({
                ...prevState,
                dec_end_date: date,
              }))
            }
          />
        </div>
      </div>
      <div className="card-header">
        <h5>
          <a
            className={"custom-accordion-title d-block py-1"}
            onClick={() => setDateRecFilter(!dateRecFilter)}
          >
            Date Recieved
            <i
              className={
                "mdi accordion-arrow mdi-chevron-" +
                (dateRecFilter ? "up" : "down")
              }
            />
          </a>
        </h5>
      </div>

      <div className={"collapse-custom" + (dateRecFilter ? " show" : "")}>
        <div className="card-body">
          <BasicDatePicker
            label="Start Date"
            currDate={props.filterOptions.rec_start_date}
            setDate={(date) =>
              props.setFilterOptions((prevState) => ({
                ...prevState,
                dec_start_date: date,
              }))
            }
          />
          <BasicDatePicker
            label="End Date"
            currDate={props.filterOptions.rec_end_date}
            setDate={(date) =>
              props.setFilterOptions((prevState) => ({
                ...prevState,
                rec_end_date: date,
              }))
            }
          />
        </div>
      </div>
    </>
  );
}
