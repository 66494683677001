import {
  TextField,
  InputAdornment,
  IconButton,
  Box,
  Button,
  ClickAwayListener,
} from "@mui/material";
import React, { useState, useRef } from "react";

export default function SearchBar({
  type,
  filterOptions,
  setStatusFilter,
  setFilterOptions,
  setRows,
  setLoading,
  searchRowsCreate,
  rowsCreate,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setFilterOptions((prevState) => ({
      ...prevState,
      search_phrase: event.target.value,
    }));
  };

  // Acceptable user displaying fails
  const fails = [
    "Search phrase cannot be empty",
    "Search Fields cannot be empty",
    "No documents found.",
  ];

  const CATCHALL_FAIL_MESSAGE = "Search Error";

  // State variable for showing add follow with its setter
  const [showAddFollow, setShowAddFollow] = useState(false);

  const refresh = async () => {
    setSearchTerm("");
    setFilterOptions((prevState) => ({
      ...prevState,
      search_phrase: "",
    }));
    setSearchHistoryShow(false);
    setErrorText(null);
    try {
      const fetchedRows = await rowsCreate(type);
      setRows((prevRows) =>
        JSON.stringify(prevRows) !== JSON.stringify(fetchedRows)
          ? fetchedRows
          : prevRows
      );
    } catch (error) {
      console.error("Failed to fetch rows:", error);
    }
  };

  // Handler for initiating a search
  const handleSearch = (event, currFilterOptions) => {
    if (event) {
      event.preventDefault();
    }
    blurFocus();
    (async () => {
      setSearchHistoryShow(false);
      try {
        if (!currFilterOptions.search_phrase) {
          setErrorText("Please input a value");
          return;
        }
        setLoading(true);
        const fetchedRows = await searchRowsCreate(type, currFilterOptions);

        if (typeof fetchedRows === "string") {
          setRows([]);
          const isInFails = fails.includes(fetchedRows);
          if (isInFails) {
            setErrorText(fetchedRows);
          } else {
            setErrorText(CATCHALL_FAIL_MESSAGE);
          }

          setLoading(false);
          return;
        }

        // reset any potential errors that occured before
        setErrorText(null);
        addSearchHistory(currFilterOptions.search_phrase);

        setRows((prevRows) =>
          JSON.stringify(prevRows) !== JSON.stringify(fetchedRows)
            ? fetchedRows
            : prevRows
        );
        setLoading(false);
      } catch (error) {
        setErrorText("Error processing search");
        setLoading(false);
        setRows([]);
      }
    })();

    //setShowAddFollow(true);
  };

  const [errorText, setErrorText] = useState(null);

  // Search History
  const [searchHistoryShow, setSearchHistoryShow] = useState(false);
  const searchHistory = useRef([]);
  const addSearchHistory = (searchPhrase) => {
    searchHistory.current = searchHistory.current.filter(
      (item) => item !== searchPhrase
    );
    if (searchHistory.current && searchHistory.current.length > 7) {
      searchHistory.current.pop();
    }
    searchHistory.current = [searchPhrase, ...searchHistory.current];
  };
  const handleHistorySearch = (searchPhrase) => {
    const currFilterOptions = {
      ...filterOptions,
      search_phrase: searchPhrase,
    };
    setSearchTerm(searchPhrase);
    setFilterOptions((prevState) => ({
      ...prevState,
      search_phrase: searchPhrase,
    }));
    handleSearch(null, currFilterOptions);
  };

  const textFieldRef = useRef(null);
  const blurFocus = () => {
    textFieldRef.current.blur();
  };

  return (
    <>
      <form
        onSubmit={(event) => handleSearch(event, filterOptions)}
        autoComplete="off"
      >
        <Box
          sx={{ display: "flex", alignItems: "center", mt: 3, mb: 4, ml: 1 }}
        >
          <div
            style={{
              position: "relative",
              width: "50%",
              maxWidth: "600px",
              minWidth: "330px",
              zIndex: 99,
            }}
          >
            <ClickAwayListener onClickAway={() => setSearchHistoryShow(false)}>
              <TextField
                inputRef={textFieldRef}
                error={errorText ? true : false}
                helperText={errorText ? errorText : null}
                variant="outlined"
                placeholder={`Search ${type}...`}
                value={searchTerm}
                onChange={handleSearchChange}
                onFocus={() => setSearchHistoryShow(true)}
                sx={{
                  borderRadius: showAddFollow ? "20px 0 0 20px" : "20px", // Conditionally adjust border-radius
                  height: "40px",
                  width: "100%", // Adjust width as needed or use flex properties
                  ".MuiOutlinedInput-root": {
                    borderRadius: {
                      xs: "20px", // Ensures borderRadius is 20px on small screens
                      sm: showAddFollow ? "20px 0 0 20px" : "20px", // Adjusts based on showAddFollow for larger screens
                    },
                    "@media (max-width: 380px)": {
                      borderRadius: "20px", // Overrides other settings when screen width is narrower than 380px
                    },
                    // Conditionally adjust border-radius
                    height: "inherit", // Match the height of the search bar
                    padding: "0px 6px",
                  },
                  ".MuiOutlinedInput-input": {
                    padding: "10px 14px",
                  },
                  ".MuiInputAdornment-positionEnd": {
                    marginRight: "0px",
                  },
                  ".MuiIconButton-root": {
                    padding: "5px",
                  },
                  ".MuiInputBase-input": {
                    fontFamily: "Nunito, sans-serif",
                    color: "#838c96",
                    fontSize: "0.9rem",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchTerm && (
                        <IconButton onClick={refresh}>
                          <i className="mdi mdi-close" />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={() => setStatusFilter((prev) => !prev)}
                      >
                        <i className="mdi mdi-filter-variant" />
                      </IconButton>
                      <IconButton
                        onClick={(event) => handleSearch(event, filterOptions)}
                        type="submit"
                      >
                        <i className="mdi mdi-magnify" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </ClickAwayListener>
            {showAddFollow && (
              <Button
                sx={{
                  borderRadius: "0 20px 20px 0", // Only right side rounded
                  height: "40px", // Match the height of the search bar
                  backgroundColor: "blue", // Adjust color as needed
                  color: "white", // Text color
                  textTransform: "none", // Prevents uppercase transformation
                  "&:hover": {
                    backgroundColor: "darkblue", // Adjust hover color as needed
                  },
                  "@media (max-width: 380px)": {
                    display: "none", // Hides the button on screens narrower than 380px
                  },
                }}
                onClick={() => {
                  /* Implement "Add Follow" logic here */
                }}
              >
                Add Follow
              </Button>
            )}
            {searchHistoryShow &&
              searchHistory.current &&
              searchHistory.current.length > 0 && (
                <div
                  className="dropdown-menu d-block"
                  aria-labelledby="dropdownMenuLink"
                  id="search-dropdown"
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: "3%",
                    width: "90%",
                  }}
                >
                  {searchHistory.current.map((prevSearch) => (
                    <div
                      className="dropdown-item"
                      key={prevSearch}
                      onClick={() => handleHistorySearch(prevSearch)}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="mdi mdi-magnify text-muted"></i>{" "}
                      {prevSearch}
                    </div>
                  ))}
                </div>
              )}
          </div>
        </Box>
      </form>
    </>
  );
}
