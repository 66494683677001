import { currentYear } from "../utils/utils.js";
import dayjs from "dayjs";

// Initalize Filter Options

const denovoOptions = [
  "denovo_num",
  "device_name",
  "regulation_number",
  "device_classification_name",
  "classification_product_code",
  "requester",
  "contact",
  "intended_use",
  "indications_for_use",
  "reclassification_order_pdf_text",
  "decision_summary_pdf_text",
];

const _510kOptions = [
  "k_num",
  "device_name",
  "regulation_number",
  "device_classification_name",
  "classification_product_code",
  "subsequent_product_code",
  "secondary_product_code",
  "applicant",
  "applicant_contact",
  "correspondent",
  "correspondent_contact",
  "summary_statement_iu_indfu",
  "decision_summary_iu",
  "decision_summary_indfu",
  "summary_pdf_text",
  "statement_pdf_text",
  "decision_summary_pdf_text",
];

const _510kTypes = [
  "Traditional",
  "510(k) Traditional",
  "Special",
  "510(k) Special",
  "Abbreviated",
  "Dual Track",
];

const _510kDecisions = [
  "SESP",
  "SESU",
  "ST",
  "PT",
  "SESE",
  "SESK",
  "SEKD",
  "SI",
  "SESD",
  "SN",
];

const pmaOptions = [
  "full_pma_num",
  "device",
  "generic_name",
  "classification_name",
  "docket_number",
  "regulation_number",
  "product_code",
  "applicant",
  "approval_order_statement",
  "summary_pdf_text",
  "approval_order_pdf_text",
  "review_memo_pdf_text",
];

const pmaSupplReasons = [
  "Change Design/Components/Specifications/Material",
  "Process Change - Manufacturer/Sterilizer/Packager/Supplier",
  "Labeling Change - Indications/instructions/shelf Life/tradename",
  "Location Change - Manufacturer/Sterilizer/Packager/Supplier",
  "Postapproval Study Protocol",
  "Labeling Change - PAS",
  "Postapproval Study Protocol - OSB",
  "Express GMP Supplement",
  "Other",
];

const pmaSupplTypes = [
  "Original",
  "Panel Track",
  "Normal 180 Day Track",
  "Normal 180 Day Track No User Fee",
  "Real-Time Process",
  "Real Time Process",
  "Special Supplement",
  "Special (Immediate Track)",
  "30-Day Notice",
  "30 Day Supplement",
  "30-Day Supplement",
  "135 Review Track For 30-Day Notice",
  "135 Day Track For 30 Day Notification",
  "Expedited PMA",
];

const yesNoOptions = ["Yes", "No"];

const panelOptions = [
  "Anesthesiology",
  "Cardiovascular",
  "Clinical Chemistry",
  "Dental",
  "Ear Nose & Throat",
  "Gastroenterology/Urology",
  "General & Plastic Surgery",
  "General Hospital",
  "Hematology",
  "Immunology",
  "Microbiology",
  "Molecular Genetics",
  "Neurology",
  "Obstetrics/Gynecology",
  "Ophthalmic",
  "Orthopedic",
  "Pathology",
  "Physical Medicine",
  "Radiology",
  "Toxicology",
];

const centerOptions = ["CDRH", "CBER"];

const initialFlag = 0;

export const getFilterOptions = (deviceType) => {
  if (deviceType === "fda_denovo" || deviceType === "denovo") {
    return {
      index: "fda_denovo",
      search_phrase: null,
      fields: denovoOptions,
      dec_start_date: dayjs("1990-01-01"),
      dec_end_date: dayjs(`${currentYear}-12-31`),
      rec_start_date: dayjs("1990-01-01"),
      rec_end_date: dayjs(`${currentYear}-12-31`),
      type: ["Direct", "Post-NSE"],
      panels: panelOptions,
      centers: centerOptions,
      size: 500,
      exact_flag: initialFlag,
    };
  } else if (deviceType === "fda_510k" || deviceType === "510k") {
    return {
      index: "fda_510k",
      search_phrase: null,
      fields: _510kOptions,
      dec_start_date: dayjs("1970-01-01"),
      dec_end_date: dayjs(`${currentYear}-12-31`),
      rec_start_date: dayjs("1970-01-01"),
      rec_end_date: dayjs(`${currentYear}-12-31`),
      type: _510kTypes,
      decision: _510kDecisions,
      panels: panelOptions,
      centers: centerOptions,
      size: 500,
      exact_flag: initialFlag,
    };
  } else if (deviceType === "fda_pma" || deviceType === "pma") {
    return {
      index: "fda_pma",
      search_phrase: null,
      fields: pmaOptions,
      dec_start_date: dayjs("1970-01-01"),
      dec_end_date: dayjs(`${currentYear}-12-31`),
      rec_start_date: dayjs("1970-01-01"),
      rec_end_date: dayjs(`${currentYear}-12-31`),
      supplement_type: pmaSupplTypes,
      supplement_reason: pmaSupplReasons,
      // combo and exprg not implemented, will implement if demand is there
      combination_product: yesNoOptions,
      expedited_review_granted: yesNoOptions,
      panels: panelOptions,
      centers: centerOptions,
      size: 500,
      exact_flag: initialFlag,
    };
  }
};
