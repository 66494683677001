import { useState } from "react";
import Forms from "../../../utils/filterBarComponents/Forms.jsx";
import {
  primaryForm,
  subForm,
} from "../../../utils/filterBarComponents/consts.js";

const deviceFields = [
  ["full_pma_num", "PMA Number"],
  ["device", "Device"],
  ["generic_name", "Generic Name"],
  ["classification_name", "Classification Name"],
  ["docket_number", "Docket Number"],
  ["regulation_number", "Regulation Number"],
  ["product_code", "Product Code"],
  ["applicant", "Applicant"],
];

const otherDeviceFields = [
  ["approval_order_statement", "Approval Order Statement"],
  ["summary_pdf_text", "Summary (SSED)"],
  ["approval_order_pdf_text", "Approval Order (PDF)"],
  ["review_memo_pdf_text", "Review Memo"],
];

function PmaFilter(props) {
  const [keyWordFilter, setKeyWordFilter] = useState(false);

  const inFields = (field) => {
    return props.filterOptions.fields.includes(field);
  };

  const updateFilter = (prevState, field) => {
    const fieldsSet = new Set(prevState.fields);
    if (fieldsSet.has(field)) {
      fieldsSet.delete(field);
    } else {
      fieldsSet.add(field);
    }
    return { ...prevState, fields: Array.from(fieldsSet) };
  };

  const fieldsFilterUpdate = (field) => {
    props.setFilterOptions((prevState) => updateFilter(prevState, field));
  };

  const masterInFilters = (typeField) => {
    if (typeField === "device") {
      return deviceFields.some((curr) =>
        props.filterOptions.fields.includes(curr[0])
      );
    }
  };

  const updateFilterOptions = (fieldsToUpdate, checked, optionsKey) => {
    if (checked) {
      const existingFieldsSet = new Set(props.filterOptions[optionsKey]);
      const toAdd = fieldsToUpdate
        .filter(([fieldName]) => !existingFieldsSet.has(fieldName))
        .map(([fieldName]) => fieldName);

      props.setFilterOptions((prevState) => ({
        ...prevState,
        [optionsKey]: [...prevState[optionsKey], ...toAdd],
      }));
    } else {
      const fieldsSet = new Set(fieldsToUpdate.map(([fieldName]) => fieldName));
      const newFields = props.filterOptions[optionsKey].filter(
        (fieldName) => !fieldsSet.has(fieldName)
      );

      props.setFilterOptions((prevState) => ({
        ...prevState,
        [optionsKey]: newFields,
      }));
    }
  };

  const masterFilterUpdate = (checked, typeField) => {
    switch (typeField) {
      case "device":
        updateFilterOptions(deviceFields, checked, "fields");
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="card-header">
        <h5>
          <a
            className={"custom-accordion-title d-block py-1"}
            onClick={() => setKeyWordFilter(!keyWordFilter)}
          >
            Search key words within...
            <i
              className={
                "mdi accordion-arrow mdi-chevron-" +
                (keyWordFilter ? "up" : "down")
              }
            />
          </a>
        </h5>
      </div>
      <div className={"collapse-custom" + (keyWordFilter ? " show" : "")}>
        <div className="card-body">
          <div className="form-check form-checkbox-primary mb-2">
            <input
              type="checkbox"
              className="form-check-input"
              checked={masterInFilters("device")}
              onChange={(e) => masterFilterUpdate(e.target.checked, "device")}
            />
            <label className="form-check-label">Device</label>
          </div>
          <Forms
            inFilter={inFields}
            filterUpdate={fieldsFilterUpdate}
            fields={deviceFields}
            type={subForm}
          />
          <Forms
            inFilter={inFields}
            filterUpdate={fieldsFilterUpdate}
            fields={otherDeviceFields}
            type={primaryForm}
          />
        </div>
      </div>
    </>
  );
}

export default PmaFilter;
