import { useState } from "react";
import Forms from "../../../utils/filterBarComponents/Forms.jsx";
import {
  primaryForm,
  subForm,
} from "../../../utils/filterBarComponents/consts.js";

const deviceFields = [
  ["denovo_num", "Denovo Number"],
  ["device_name", "Device Name"],
  ["regulation_number", "Regulation Number"],
  ["device_classification_name", "Device Classification Name"],
  ["classification_product_code", "Product Code"],
];

const reqFields = [
  ["requester", "Requester Company"],
  ["contact", "Requester Contact"],
];

const otherDeviceFields = [
  ["indications_for_use", "Intended Use / Indications for Use"],
  ["reclassification_order_pdf_text", "Reclassification Order"],
  ["decision_summary_pdf_text", "Decision Summary"],
];

function DenovoFilter(props) {
  const [keyWordFilter, setKeyWordFilter] = useState(false);

  const inFields = (field) => {
    return props.filterOptions.fields.includes(field);
  };

  const fieldsFilterUpdate = (field) => {
    // Seperate if statement because 2 fields need to be added
    if (field === "indications_for_use") {
      if (inFields(field)) {
        props.setFilterOptions((prevState) => ({
          ...prevState,
          fields: prevState.fields.filter(
            (item) => item !== field && item !== "intended_use"
          ),
        }));
      } else {
        props.setFilterOptions((prevState) => ({
          ...prevState,
          fields: [...prevState.fields, field, "intended_use"],
        }));
      }
    } else {
      if (inFields(field)) {
        props.setFilterOptions((prevState) => ({
          ...prevState,
          fields: prevState.fields.filter((item) => item !== field),
        }));
      } else {
        props.setFilterOptions((prevState) => ({
          ...prevState,
          fields: [...prevState.fields, field],
        }));
      }
    }
  };

  const masterInFilters = (typeField) => {
    if (typeField === "device") {
      return deviceFields.some((curr) =>
        props.filterOptions.fields.includes(curr[0])
      );
    } else if (typeField === "req") {
      return reqFields.some((curr) =>
        props.filterOptions.fields.includes(curr[0])
      );
    }
  };

  const updateFilterOptions = (fieldsToUpdate, checked, optionsKey) => {
    if (checked) {
      const existingFieldsSet = new Set(props.filterOptions[optionsKey]);
      const toAdd = fieldsToUpdate
        .filter(([fieldName]) => !existingFieldsSet.has(fieldName))
        .map(([fieldName]) => fieldName);

      props.setFilterOptions((prevState) => ({
        ...prevState,
        [optionsKey]: [...prevState[optionsKey], ...toAdd],
      }));
    } else {
      const fieldsSet = new Set(fieldsToUpdate.map(([fieldName]) => fieldName));
      const newFields = props.filterOptions[optionsKey].filter(
        (fieldName) => !fieldsSet.has(fieldName)
      );

      props.setFilterOptions((prevState) => ({
        ...prevState,
        [optionsKey]: newFields,
      }));
    }
  };

  const masterFilterUpdate = (checked, typeField) => {
    switch (typeField) {
      case "device":
        updateFilterOptions(deviceFields, checked, "fields");
        break;
      case "req":
        updateFilterOptions(reqFields, checked, "fields");
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="card-header">
        <h5>
          <a
            className={"custom-accordion-title d-block py-1"}
            onClick={() => setKeyWordFilter(!keyWordFilter)}
          >
            Search key words within...
            <i
              className={
                "mdi accordion-arrow mdi-chevron-" +
                (keyWordFilter ? "up" : "down")
              }
            />
          </a>
        </h5>
      </div>
      <div className={"collapse-custom" + (keyWordFilter ? " show" : "")}>
        <div className="card-body">
          <div className="form-check form-checkbox-primary mb-2">
            <input
              type="checkbox"
              className="form-check-input"
              checked={masterInFilters("device")}
              onChange={(e) => masterFilterUpdate(e.target.checked, "device")}
            />
            <label className="form-check-label">Device</label>
          </div>
          <Forms
            inFilter={inFields}
            filterUpdate={fieldsFilterUpdate}
            fields={deviceFields}
            type={subForm}
          />
          <div className="form-check form-checkbox-primary mb-2">
            <input
              type="checkbox"
              className="form-check-input"
              checked={masterInFilters("req")}
              onChange={(e) => masterFilterUpdate(e.target.checked, "req")}
            />
            <label className="form-check-label">Requester</label>
          </div>
          <Forms
            inFilter={inFields}
            filterUpdate={fieldsFilterUpdate}
            fields={reqFields}
            type={subForm}
          />
          <Forms
            inFilter={inFields}
            filterUpdate={fieldsFilterUpdate}
            fields={otherDeviceFields}
            type={primaryForm}
          />
        </div>
      </div>
    </>
  );
}

export default DenovoFilter;
