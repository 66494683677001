import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "./pages/landing/Landing";
import ErrorPage from "./pages/error/ErrorPage";
import Denovo from "./pages/device/denovo/Denovo";
import Pma from "./pages/device/pma/Pma";
import _510k from "./pages/device/_510k/_510k";
import Guidance from "./pages/resource/guidance/Guidance";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route index element={<Landing />} />
        <Route path="/landing" element={<Landing />} />
        <Route path="/p/device/USFDA/denovo" element={<Denovo />} />
        <Route path="/p/device/USFDA/510k" element={<_510k />} />
        <Route path="/p/device/USFDA/PMA" element={<Pma />} />
        <Route path="/p/resource/USFDA/guidance" element={<Guidance />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
