import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import { useRef, useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Nunito, sans-serif",
  },
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  backgroundColor: "#f1f3fa",
  borderRadius: "20px",
});

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  "&.MuiToggleButton-root": {
    borderRadius: "18px",
    border: "none",
    color: "#313a46",
    padding: "3px 18px",
    fontSize: "0.9375rem",
    textTransform: "none",
    fontWeight: 700,
    "&.Mui-selected": {
      color: "#fff",
      backgroundColor: "#3688fc",
    },
  },
}));

function ToggleSearchModeButton({ filterOptions, setFilterOptions }) {
  const [alignment, setAlignment] = useState(filterOptions.exact_flag);

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      setFilterOptions((prev) => ({
        ...prev,
        exact_flag: newAlignment,
      }));
    }
  };

  const centerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  };

  return (
    <div style={centerStyle}>
      <ThemeProvider theme={theme}>
        <StyledToggleButtonGroup
          size="small"
          value={alignment}
          exclusive
          onChange={handleAlignment}
          aria-label="text alignment"
        >
          <StyledToggleButton value={1} aria-label="left aligned">
            Exact
          </StyledToggleButton>
          <StyledToggleButton value={0} aria-label="centered">
            Match
          </StyledToggleButton>
        </StyledToggleButtonGroup>
      </ThemeProvider>
    </div>
  );
}

function FilterBar({
  status,
  setStatusFalseFilter,
  filterOptions,
  setFilterOptions,
  type,
  FilterBody,
}) {
  // Search Key Words Within
  const filterRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const isInsideFilterRef =
        filterRef.current && filterRef.current.contains(event.target);
      // for if user is choosing date from mui: as the div for choosing is rendered outside ref
      const isInsideDialog = event.target.closest('[role="dialog"]');

      if (!isInsideFilterRef && !isInsideDialog) {
        setStatusFalseFilter();
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return (
    <>
      {status === "show" && <div className="offcanvas-backdrop fade show" />}
      <div
        className={
          "offcanvas offcanvas-start " + (status === "show" ? "show" : "")
        }
        data-bs-scroll="false"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
        style={{
          visibility: status === "show" ? "visible" : "hidden",
          padding: "0 11px",
        }}
        {...(status === "show"
          ? { "aria-modal": true, "aria-hidden": "false" }
          : { "aria-hidden": "true" })}
        role="dialog"
        ref={filterRef}
      >
        <div className="offcanvas-header">
          <h4 className="offcanvas-title">Search & Filter</h4>
          <button
            className="btn-close me-1"
            onClick={setStatusFalseFilter}
          ></button>
        </div>
        <div className="offcanvas-body">
          <ul className="nav nav-tabs nav-bordered nav-justified mb-3">
            <li className="nav-item">
              <a className={"nav-link active"}>
                <i className="mdi mdi-text-search d-md-none d-block" />
                <span className="d-none d-md-block">Filter</span>
              </a>
            </li>
          </ul>
          <ToggleSearchModeButton
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
          />
          <div className="tab-content">
            <div className="accordion custom-accordion mt-3">
              <div className="card mb-0">
                <FilterBody
                  type={type}
                  filterOptions={filterOptions}
                  setFilterOptions={setFilterOptions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FilterBar;
