import { useState } from "react";
import Forms from "../../../utils/filterBarComponents/Forms.jsx";
import { subForm } from "../../../utils/filterBarComponents/consts.js";

const decisionOptions = [
  ["SESP", "SESP"],
  ["SESU", "SESU"],
  ["ST", "ST"],
  ["PT", "PT"],
  ["SESE", "SESE"],
  ["SESK", "SESK"],
  ["SEKD", "SEKD"],
  ["SI", "SI"],
  ["SESD", "SESD"],
  ["SN", "SN"],
];

const typeOptions = [
  ["Traditional", "Traditional"],
  ["Special", "Special"],
  ["Abbreviated", "Abbreviated"],
  ["Dual Track", "Dual Track"],
];

function _510kDecisionType(props) {
  const [decisionFilter, setdecisionFilter] = useState(false);

  const inDecision = (field) => {
    return props.filterOptions.decision.includes(field);
  };

  const decisionFilterUpdate = (field) => {
    if (inDecision(field)) {
      props.setFilterOptions((prevState) => ({
        ...prevState,
        decision: prevState.decision.filter((item) => item !== field),
      }));
    } else {
      props.setFilterOptions((prevState) => ({
        ...prevState,
        decision: [...prevState.decision, field],
      }));
    }
  };

  const inType = (field) => {
    return props.filterOptions.type.includes(field);
  };

  const typeFilterUpdate = (field) => {
    if (field === "Traditional") {
      if (inType(field)) {
        props.setFilterOptions((prevState) => ({
          ...prevState,
          type: prevState.type.filter(
            (item) => item !== field && item !== "510(k) Traditional"
          ),
        }));
      } else {
        props.setFilterOptions((prevState) => ({
          ...prevState,
          type: [...prevState.type, field, "510(k) Traditional"],
        }));
      }
    } else if (field === "Special") {
      if (inType(field)) {
        props.setFilterOptions((prevState) => ({
          ...prevState,
          type: prevState.type.filter(
            (item) => item !== field && item !== "510(k) Special"
          ),
        }));
      } else {
        props.setFilterOptions((prevState) => ({
          ...prevState,
          type: [...prevState.type, field, "510(k) Special"],
        }));
      }
    } else {
      if (inType(field)) {
        props.setFilterOptions((prevState) => ({
          ...prevState,
          type: prevState.type.filter((item) => item !== field),
        }));
      } else {
        props.setFilterOptions((prevState) => ({
          ...prevState,
          type: [...prevState.type, field],
        }));
      }
    }
  };

  const masterInFilters = (typeField) => {
    if (typeField === "decision") {
      return decisionOptions.some((curr) =>
        props.filterOptions.decision.includes(curr[0])
      );
    } else if (typeField === "type") {
      return typeOptions.some((curr) =>
        props.filterOptions.type.includes(curr[0])
      );
    } else {
      return false;
    }
  };

  const updateFilterOptions = (fieldsToUpdate, checked, optionsKey) => {
    if (checked) {
      const existingFieldsSet = new Set(props.filterOptions[optionsKey]);
      const toAdd = fieldsToUpdate
        .filter(([fieldName]) => !existingFieldsSet.has(fieldName))
        .map(([fieldName]) => fieldName);

      props.setFilterOptions((prevState) => ({
        ...prevState,
        [optionsKey]: [...prevState[optionsKey], ...toAdd],
      }));
    } else {
      const fieldsSet = new Set(fieldsToUpdate.map(([fieldName]) => fieldName));
      const newFields = props.filterOptions[optionsKey].filter(
        (fieldName) => !fieldsSet.has(fieldName)
      );

      props.setFilterOptions((prevState) => ({
        ...prevState,
        [optionsKey]: newFields,
      }));
    }
  };

  const masterFilterUpdate = (checked, typeField) => {
    switch (typeField) {
      case "decision":
        updateFilterOptions(decisionOptions, checked, "decision");
        break;
      case "type":
        updateFilterOptions(typeOptions, checked, "type");
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="card-header">
        <h5>
          <a
            className={"custom-accordion-title d-block py-1"}
            onClick={() => setdecisionFilter(!decisionFilter)}
          >
            Decision & Type
            <i
              className={
                "mdi accordion-arrow mdi-chevron-" +
                (decisionFilter ? "up" : "down")
              }
            />
          </a>
        </h5>
      </div>
      <div className={"collapse-custom" + (decisionFilter ? " show" : "")}>
        <div className="card-body">
          <div className="form-check form-checkbox-primary mb-2">
            <input
              type="checkbox"
              className="form-check-input"
              checked={masterInFilters("decision")}
              onChange={(e) => masterFilterUpdate(e.target.checked, "decision")}
            />
            <label className="form-check-label">Decision</label>
          </div>
          <Forms
            inFilter={inDecision}
            filterUpdate={decisionFilterUpdate}
            fields={decisionOptions}
            type={subForm}
          />
          <div className="form-check form-checkbox-primary mb-2">
            <input
              type="checkbox"
              className="form-check-input"
              checked={masterInFilters("type")}
              onChange={(e) => masterFilterUpdate(e.target.checked, "type")}
            />
            <label className="form-check-label">Type</label>
          </div>
          <Forms
            inFilter={inType}
            filterUpdate={typeFilterUpdate}
            fields={typeOptions}
            type={subForm}
          />
        </div>
      </div>
    </>
  );
}

export default _510kDecisionType;
