import { useState, useEffect } from "react";
import {
  InformationRow,
  SourceLink,
  HighlightText,
  FRSourceLink,
} from "../utils/HighlightUtils";
import {
  SubField,
  SubFieldBulletPoint,
  OneLine,
} from "../utils/ProfileUtils.jsx";
import { convertDatetime } from "../utils/utils.js";

const GuidanceResource = ({ highlightData }) => (
  <>
    <InformationRow
      title="Guidance Title:"
      value={highlightData.guidance_title}
      highlightValue={highlightData.highlight.guidance_title}
      additlStyle={"mb-1"}
    />
    <OneLine
      titleText={"Issue Date:"}
      dataText={convertDatetime(highlightData.issue_date)}
    />
    {highlightData.fda_organization && (
      <SubFieldBulletPoint
        titleText={"FDA Organizations:"}
        dataText={highlightData.fda_organization}
      />
    )}
    <SubField titleText={"Topic:"} dataText={highlightData.topic} />
    {highlightData.regulated_products && (
      <SubFieldBulletPoint
        titleText={"Regulated Products:"}
        dataText={highlightData.regulated_products}
      />
    )}
    <SubField titleText={"Status:"} dataText={highlightData.guidance_status} />
    <SubField
      titleText={"Open For Comment:"}
      dataText={highlightData.open_for_comment}
    />
  </>
);

function GuidanceHighlightBody({ highlightData, statusHighlight }) {
  // Intalize State
  const [currTab, setCurrTab] = useState("Resource");
  const setTab = (switchTab) => {
    setCurrTab(switchTab);
  };

  // Reset tab when toggling highlight
  useEffect(() => setCurrTab("Resource"), [statusHighlight]);

  const NavLinks = ({ highlightData }) => (
    <ul className="nav nav-tabs nav-bordered nav-justified mb-3">
      <li className="nav-item">
        <a
          className={"nav-link" + (currTab === "Resource" ? " active" : "")}
          onClick={() => setTab("Resource")}
        >
          <i className="mdi mdi-subtitles-outline d-md-none d-block"></i>
          <span className="d-none d-md-block">Guidance</span>
        </a>
      </li>
      {highlightData.highlight.guidance_abstract && (
        <li className="nav-item">
          <a
            className={
              "nav-link" + (currTab === "Guidance Summary" ? " active" : "")
            }
            onClick={() => setTab("Guidance Summary")}
          >
            <i className="mdi mdi-subtitles-outline d-md-none d-block"></i>
            <span className="d-none d-md-block">Guidance Summary</span>
          </a>
        </li>
      )}
      {highlightData.highlight.guidance_pdf_text && (
        <li className="nav-item">
          <a
            className={
              "nav-link" + (currTab === "Guidance (PDF)" ? " active" : "")
            }
            onClick={() => setTab("Guidance (PDF)")}
          >
            <i className="mdi mdi-subtitles-outline d-md-none d-block"></i>
            <span className="d-none d-md-block">Guidance (PDF)</span>
          </a>
        </li>
      )}
      {highlightData.highlight.fr_abstract && (
        <li className="nav-item">
          <a
            className={
              "nav-link" + (currTab === "FR Abstract" ? " active" : "")
            }
            onClick={() => setTab("FR Abstract")}
          >
            <i className="mdi mdi-subtitles-outline d-md-none d-block"></i>
            <span className="d-none d-md-block">FR Abstract</span>
          </a>
        </li>
      )}
    </ul>
  );

  return (
    <>
      <NavLinks highlightData={highlightData} />
      {currTab === "Resource" && (
        <>
          <SourceLink linkText={"FDA"} link={highlightData.guidance_link} />
          <GuidanceResource highlightData={highlightData} />
        </>
      )}
      {currTab === "Guidance Summary" && (
        <>
          <SourceLink
            linkText={"Guidance"}
            link={highlightData.guidance_pdf_link}
          />
          <HighlightText
            type={"guidance_abstract"}
            highlights={highlightData.highlight}
          />
        </>
      )}
      {currTab === "Guidance (PDF)" && (
        <>
          <SourceLink
            linkText={"Guidance"}
            link={highlightData.guidance_pdf_link}
          />
          <HighlightText
            type={"guidance_pdf_text"}
            highlights={highlightData.highlight}
          />
        </>
      )}
      {currTab === "FR Abstract" && (
        <>
          <FRSourceLink
            linkText={"Federal Register PDF"}
            link={highlightData.fr_pdf_url}
          />
          <HighlightText
            type={"fr_abstract"}
            highlights={highlightData.highlight}
          />
        </>
      )}
    </>
  );
}

function HighlightBody({ highlightData, statusHighlight }) {
  if (highlightData.index_name === "fda_guidance") {
    return (
      <GuidanceHighlightBody
        highlightData={highlightData}
        statusHighlight={statusHighlight}
      />
    );
  }
}

export default HighlightBody;
