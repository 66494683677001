import { useRef, useEffect, useState } from "react";

export default function Highlight({
  statusHighlight,
  highlightData,
  setStatusFalseHighlight,
  highlightTitle,
  HighlightBody,
}) {
  const highlightRef = useRef(null);

  const title = highlightData ? highlightTitle(highlightData) : null;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        highlightRef.current &&
        !highlightRef.current.contains(event.target)
      ) {
        setStatusFalseHighlight();
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    let timer;
    if (!statusHighlight) {
      // Wait for the animation to complete before hiding the modal
      timer = setTimeout(() => {
        setIsVisible(false);
      }, 250);
    } else {
      setIsVisible(true);
    }

    return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts
  }, [statusHighlight]);

  return (
    <>
      {statusHighlight && <div className="offcanvas-backdrop fade show" />}
      <div
        className={
          "modal-position modal fade" + (statusHighlight ? " show" : " hide")
        }
        tabIndex="-1"
        role="dialog"
        aria-labelledby="standard-modalLabel"
        {...(statusHighlight
          ? { "aria-modal": "true" }
          : { "aria-hidden": "true" })}
        style={{ display: isVisible ? "block" : "none" }}
      >
        <div className="modal-dialog  modal-lg modal-dialog-scrollable">
          <div className="modal-content" ref={highlightRef}>
            {highlightData ? (
              <>
                <div className="modal-header">
                  <h5 className="modal-title">
                    Highlights - {title} ({highlightData.hits_in_doc}{" "}
                    {highlightData.hits_in_doc === 1 ? "hit" : "hits"})
                  </h5>
                  <button
                    className="btn-close"
                    onClick={setStatusFalseHighlight}
                  />
                </div>
                <div className="modal-body">
                  <HighlightBody
                    highlightData={highlightData}
                    statusHighlight={statusHighlight}
                  />
                </div>
              </>
            ) : (
              <div
                className="loading-container"
                style={{ height: "250px", width: "100%" }}
              >
                <div
                  className="spinner-border text-primary"
                  role="status"
                ></div>
              </div>
            )}
            <div className="modal-footer ">
              <button
                className="btn btn-light"
                onClick={setStatusFalseHighlight}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
