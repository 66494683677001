import { convertDatetime } from "../utils/utils.js";
import {
  TextLink,
  SubField,
  SubFieldBulletPoint,
  OneLine,
  SubWithField,
} from "../utils/ProfileUtils.jsx";

function GuidanceBodyProfile({ profileOn, resource }) {
  if (profileOn) {
    return (
      <div className={"tab-pane fade active" + (profileOn ? " show" : "")}>
        <div className="mb-1">
          <TextLink
            linkUrl={resource.guidance_pdf_link}
            title={"Guidance Document"}
            align={""}
          />
          <TextLink
            linkUrl={resource.fr_pdf_url}
            title={"Federal Register (PDF)"}
            align={"ms-sm-1"}
          />
        </div>
        <OneLine
          titleText={"Issue Date:"}
          dataText={convertDatetime(resource.issue_date)}
        />
        {resource.fda_organization && (
          <SubFieldBulletPoint
            titleText={"FDA Organizations:"}
            dataText={resource.fda_organization}
          />
        )}
        <SubField titleText={"Topic:"} dataText={resource.topic} />
        {resource.regulated_products && (
          <SubFieldBulletPoint
            titleText={"Regulated Products:"}
            dataText={resource.regulated_products}
          />
        )}
        <SubField titleText={"Status:"} dataText={resource.guidance_status} />
        <SubField
          titleText={"Open For Comment:"}
          dataText={resource.open_for_comment}
        />
        <SubField
          titleText={"Summary:"}
          dataText={resource.guidance_abstract}
        />
      </div>
    );
  } else {
    return (
      <div className={"tab-pane fade active" + (profileOn ? "" : " show")}>
        <h5>FDA Guidance Docket Info</h5>

        {resource.docket_number_link ? (
          <SubWithField
            titleText={"Docket Number:"}
            dataText={resource.docket_number_text}
            link={resource.docket_number_link}
          />
        ) : (
          <SubField
            titleText={"Docket Number:"}
            dataText={resource.docket_number_text}
          />
        )}
        <SubField
          titleText={"Publication Date:"}
          dataText={convertDatetime(resource.fr_publication_date)}
        />
        <SubField
          titleText={"Comments Close Date:"}
          dataText={convertDatetime(resource.comment_closing_date_on_draft)}
        />
        <hr />
        <SubField
          titleText={"Federal Register Abstract:"}
          dataText={resource.fr_abstract}
        />
      </div>
    );
  }
}

export { GuidanceBodyProfile };
