import logo from "../../../assets/images/logo-kipra/kipra-low-resolution-logo-color-on-transparent-background.png";

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="bg-dark py-4">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center">
              <img src={logo} alt="" className="logo-dark" height="36" />
              <span className="ms-1 text-muted">
                {" "}
                Making the searching easier
              </span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="mt-2">
              <p className="text-muted mt-4 text-center mb-0">
                {currentYear} © KIPRA
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
