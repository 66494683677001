import GuidanceFilter from "./resourceFilterBarComponents/GuidanceFilter";
import BasicOptionsFilter from "../utils/filterBarComponents/BasicOptionsFilter";
import AllOptionsFilter from "../utils/filterBarComponents/AllOptionsFilter";
import DateFilter from "../utils/filterBarComponents/DateFilter";

const regulatedProductsOptions = [
  ["Animal & Veterinary", "Animal & Veterinary"],
  ["Biologics", "Biologics"],
  ["Cosmetics", "Cosmetics"],
  ["Dietary Supplements", "Dietary Supplements"],
  ["Drugs", "Drugs"],
  ["Food & Beverages", "Food & Beverages"],
  ["Medical Devices", "Medical Devices"],
  ["Radiation-Emitting Products", "Radiation-Emitting Products"],
  ["Tobacco", "Tobacco"],
];

const fdaOrganizationsOptions = [
  [
    "Center for Biologics Evaluation and Research",
    "Center for Biologics Evaluation and Research",
  ],
  [
    "Center for Devices and Radiological Health",
    "Center for Devices and Radiological Health",
  ],
  [
    "Center for Drug Evaluation and Research",
    "Center for Drug Evaluation and Research",
  ],
  [
    "Center for Food Safety and Applied Nutrition",
    "Center for Food Safety and Applied Nutrition",
  ],
  ["Center for Tobacco Products", "Center for Tobacco Products"],
  ["Center for Veterinary Medicine", "Center for Veterinary Medicine"],
  [
    "National Center for Toxicological Research",
    "National Center for Toxicological Research",
  ],
  ["Office of Regulatory Affairs", "Office of Regulatory Affairs"],
  ["Office of the Commissioner", "Office of the Commissioner"],
  ["Oncology Center of Excellence", "Oncology Center of Excellence"],
];

const statusOptions = [
  ["Draft", "Draft"],
  ["Final", "Final"],
];

export default function FilterBody({ type, filterOptions, setFilterOptions }) {
  if (type === "fda_guidance" || type === "guidance") {
    return (
      <>
        <GuidanceFilter
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
        />
        <DateFilter
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          title={"Issue Date"}
          dateKeyStart={"issue_start_date"}
          dateKeyEnd={"issue_end_date"}
        />
        <AllOptionsFilter
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          title={"FDA Organizations"}
          options={fdaOrganizationsOptions}
          optionsType={"fda_organization"}
        />
        <AllOptionsFilter
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          title={"Regulated Products"}
          options={regulatedProductsOptions}
          optionsType={"regulated_products"}
        />
        <BasicOptionsFilter
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          title={"Guidance Status"}
          options={statusOptions}
          optionsType={"guidance_status"}
        />
      </>
    );
  }
}
