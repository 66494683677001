import { convertDatetime } from "../utils/utils.js";

// Data Grid Column Creation
function guidanceColumns(callback, setHighlight) {
  const columns = [
    {
      field: "guidanceTitle",
      headerName: "Guidance Title",
      minWidth: 175,
      flex: 1.75,
      renderCell: (params) => (
        <div>
          <a href="#" onClick={() => callback(params.row.id)}>
            {params.value}
          </a>
        </div>
      ),
    },
    {
      field: "issueDate",
      headerName: "Issue Date",
      minWidth: 100,
      flex: 0.75,
      hideable: false,
      renderCell: (params) => (
        <div>
          {params.value}
          {params.row.searchRowData && (
            <div className="mt-1">
              <button
                type="button"
                className="btn rounded-pill btn-outline-success btn-sm"
                onClick={() => setHighlight(params.row.searchRowData)}
              >
                Highlights
              </button>
            </div>
          )}
        </div>
      ),
    },
    //{
    //  field: "document",
    //  headerName: "Document",
    //  minWidth: 150,
    //  flex: 1.3,
    //},
    {
      field: "fdaOrganization",
      headerName: "FDA Organization",
      minWidth: 150,
      flex: 1.6,
      renderCell: (params) => {
        const formattedValue =
          params.value && params.value.replace(/;/g, "\n").replace(/,/g, ", ");
        return <div style={{ whiteSpace: "pre-line" }}>{formattedValue}</div>;
      },
    },
    {
      field: "topic",
      headerName: "Topic",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "regulatedProducts",
      headerName: "Regulated Products",
      minWidth: 150,
      flex: 1.3,
      renderCell: (params) => {
        const formattedValue = params.value && params.value.replace(/;/g, ", ");
        return <div style={{ whiteSpace: "pre-line" }}>{formattedValue}</div>;
      },
    },
    {
      field: "guidanceStatus",
      headerName: "Guidance Status",
      minWidth: 150,
      flex: 0.75,
    },
    {
      field: "openForComment",
      headerName: "Open For Comment",
      minWidth: 100,
      flex: 0.5,
    },
  ];
  return columns;
}

async function guidanceRows() {
  const getGuidanceData = async () => {
    try {
      const response = await fetch("/api/resource/guidance/recent");
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const guidanceData = await getGuidanceData();
  let rows = [];
  guidanceData.forEach((guidance) => {
    const currRow = {
      id: guidance.id,
      guidanceTitle: guidance.guidance_title,
      issueDate: convertDatetime(guidance.issue_date),
      fdaOrganization: guidance.fda_organization,
      topic: guidance.topic,
      regulatedProducts: guidance.regulated_products,
      guidanceStatus: guidance.guidance_status,
      openForComment: guidance.open_for_comment,
      searchRowData: null,
    };
    rows.push(currRow);
  });

  return rows;
}

export const columnsCreate = (tableName, callback, setHighlight) => {
  if (tableName === "fda_guidance" || tableName === "guidance") {
    return guidanceColumns(callback, setHighlight);
  }
};

export const rowsCreate = async (tableName) => {
  if (tableName === "fda_guidance" || tableName === "guidance") {
    return guidanceRows();
  }
};
