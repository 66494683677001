import Profile from "./Profile.jsx";
import FilterBar from "../FilterBar.jsx";
import SearchBar from "../SearchBar.jsx";
import Highlight from "../Highlight.jsx";
import { columnsCreate, rowsCreate } from "../database/deviceGridData.js";
import React, { useState, useRef, useEffect } from "react";
import { getFilterOptions } from "./deviceFilter.js";
import CustomDataGrid from "../CustomDataGrid.jsx";
import FilterBody from "./FilterBody.jsx";
import { searchRowsCreate } from "../database/deviceSearch.js";
import HighlightBody from "./HighlightBody.jsx";

const columnsVis = (deviceType) => {
  const denovoDefault = {
    dateReceived: false,
    decision: false,
    type: false,
    classificationCommittee: false,
    deviceClassificationName: false,
    regulationNumber: false,
    numDaysApprove: false,
  };

  if (deviceType === "fda_denovo" || deviceType === "denovo") {
    return denovoDefault;
  } else if (deviceType === "fda_510k" || deviceType === "510k") {
    return {
      ...denovoDefault,
      regulationMedicalSpeciality: false,
      correspondent: false,
      thirdPartyReview: false,
      combinationProduct: false,
    };
  } else if (deviceType === "fda_pma" || deviceType === "pma") {
    return {
      dateReceived: false,
      pmaNumber: false,
      supplementNumber: false,
      genericName: false,
      expeditedReviewGranted: false,
      combinationProduct: false,
      regulationNumber: false,
      proCode: false,
      numDaysApprove: false,
    };
  }

  return denovoDefault;
};

const highlightTitle = (highlightData) => {
  if (highlightData.index_name === "fda_denovo") {
    return highlightData.denovo_num;
  } else if (highlightData.index_name === "fda_pma") {
    return highlightData.full_pma_num;
  } else if (highlightData.index_name === "fda_510k") {
    return highlightData.k_num;
  }
};

function DeviceBody({ deviceType }) {
  // Profile
  const [statusProfile, setStatusProfile] = useState(false);
  const currProfile = useRef();

  const setProfile = (deviceNum) => {
    setStatusProfile(true);
    currProfile.current = deviceNum;
  };

  const setStatusFalseProfile = () => {
    setStatusProfile(false);
  };

  // Highlight
  const [statusHighlight, setStatusHighlight] = useState(false);
  const currHighlight = useRef();

  const setHighlight = (highlightData) => {
    setStatusHighlight(true);
    currHighlight.current = highlightData;
  };

  const setStatusFalseHighlight = () => {
    setStatusHighlight(false);
  };

  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const fetchedRows = await rowsCreate(deviceType);
        setRows((prevRows) =>
          JSON.stringify(prevRows) !== JSON.stringify(fetchedRows)
            ? fetchedRows
            : prevRows
        );
      } catch (error) {
        console.error("Failed to fetch rows:", error);
        setRows([]);
      }
    })();

    const newColumns = columnsCreate(deviceType, setProfile, setHighlight);
    setColumns((prevColumns) =>
      JSON.stringify(prevColumns) !== JSON.stringify(newColumns)
        ? newColumns
        : prevColumns
    );
  }, [deviceType]);

  // Set Search Filter Object
  const [statusFilter, setStatusFilter] = useState(false);
  const [filterOptions, setFilterOptions] = useState(
    getFilterOptions(deviceType)
  );
  const setStatusFalseFilter = () => {
    setStatusFilter(false);
  };

  // Loading
  const [isLoading, setLoading] = useState(false);

  return (
    <div className="wrapper">
      <div className="content-page-custom">
        <div className="container-fluid table-body-height">
          <SearchBar
            type={deviceType}
            filterOptions={filterOptions}
            setStatusFilter={setStatusFilter}
            setFilterOptions={setFilterOptions}
            setRows={setRows}
            setLoading={setLoading}
            searchRowsCreate={searchRowsCreate}
            rowsCreate={rowsCreate}
          />
          <div className="card border-muted border d-block table-body-height">
            <div className="card-body table-body-height">
              <Highlight
                statusHighlight={statusHighlight}
                highlightData={currHighlight.current}
                setStatusFalseHighlight={setStatusFalseHighlight}
                highlightTitle={highlightTitle}
                HighlightBody={HighlightBody}
              />
              <FilterBar
                status={statusFilter ? "show" : ""}
                setStatusFalseFilter={setStatusFalseFilter}
                filterOptions={filterOptions}
                setFilterOptions={setFilterOptions}
                type={deviceType}
                FilterBody={FilterBody}
              />
              <Profile
                status={statusProfile ? "show" : ""}
                deviceNum={currProfile.current}
                onStateChange={setStatusFalseProfile}
                deviceType={deviceType}
              />
              <CustomDataGrid
                isLoading={isLoading}
                rows={rows}
                columns={columns}
                vis={columnsVis(deviceType)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeviceBody;
