import {
  DataGrid,
  GridToolbarQuickFilter,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridFooterContainer,
  GridPagination,
} from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { createTheme, ThemeProvider } from "@mui/material/styles";

function TableToolbar() {
  const buttonStyling = {
    marginRight: "10px",
    "& .MuiFormControlLabel-label": {
      fontFamily: "Nunito, sans-serif",
    },
  };
  return (
    <GridToolbarContainer
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <div>
        <GridToolbarColumnsButton sx={buttonStyling} />
        <GridToolbarFilterButton sx={buttonStyling} />
        <GridToolbarDensitySelector sx={buttonStyling} />
        <GridToolbarExport sx={buttonStyling} />
      </div>
      <div>
        <GridToolbarQuickFilter
          sx={{
            ...buttonStyling,
            "& .MuiInputBase-input": {
              fontFamily: "Nunito, sans-serif",
              fontSize: "0.9rem",
              color: "#838c96",
            },
          }}
        />
      </div>
    </GridToolbarContainer>
  );
}

function CustomFooter() {
  return (
    <GridFooterContainer
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
      <GridPagination
        sx={{
          "& .MuiTablePagination-displayedRows": {
            fontFamily: "Nunito, sans-serif",
            fontSize: "0.9rem",
            color: "#838c96",
          },
          "& .MuiToolbar-root": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      />
    </GridFooterContainer>
  );
}

export default function CustomDataGrid({ isLoading, rows = [], columns, vis }) {
  const theme = createTheme({
    typography: {
      fontFamily: "Nunito, sans-serif",
    },
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            fontFamily: "Nunito, sans-serif",
          },
        },
      },
    },
  });

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <ThemeProvider theme={theme}>
        <DataGrid
          loading={isLoading}
          rows={rows}
          columns={columns}
          getRowHeight={() => "auto"}
          sx={{
            "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
              py: "15px",
            },
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              py: "22px",
            },
            "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
              py: "26px",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold", // Make header title bold
            },
            "& .MuiButtonBase-root-MuiButton-root": {
              fontSize: "30rem",
            },

            fontSize: "0.9rem",
            fontWeight: "400",
            color: "#838c96",
          }}
          slots={{
            toolbar: TableToolbar,
            footer: CustomFooter,
            loadingOverlay: LinearProgress,
          }}
          initialState={{
            filter: {
              filterModel: {
                items: [],
                quickFilterExcludeHiddenColumns: true,
              },
            },
            columns: {
              columnVisibilityModel: vis,
            },
            pagination: { paginationModel: { pageSize: 100 } },
          }}
          pageSizeOptions={[100]}
        />
      </ThemeProvider>
    </div>
  );
}
