function Faq() {
  return (
    <section
      className="py-4 bg-light-lighten border-top border-bottom border-light"
      id="faq"
    >
      <div className="container">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="text-center">
                  <h1 className="mt-0">
                    <i className="mdi mdi-frequently-asked-questions"></i>
                  </h1>
                  <h3>
                    Frequently Asked{" "}
                    <span className="text-primary">Questions</span>
                  </h3>
                </div>
              </div>
            </div>

            <div className="row pt-5 pb-3">
              <div className="col-lg-5 offset-lg-1">
                {/*Question/Answer*/}
                <div>
                  <div className="faq-question-q-box">Q.</div>
                  <h4 className="faq-question" data-wow-delay=".1s">
                    What prompted the creation of KIPRA?
                  </h4>
                  <p className="faq-answer mb-4">
                    Regulatory intelligence research is an essential yet
                    time-consuming task for those striving for regulatory
                    compliance. KIPRA aims to alleviate this lengthy bottleneck
                    and enhance accessibility by providing an accurate, refined,
                    and searchable database for the vast sea of FDA regulatory
                    information.
                  </p>
                </div>

                {/*Question/Answer*/}
                <div>
                  <div className="faq-question-q-box">Q.</div>
                  <h4 className="faq-question" data-wow-delay=".1s">
                    Best Search Practices?
                  </h4>
                  <p className="faq-answer mb-4">
                    It is recommended that exact search is used when searching
                    for specific keywords and should be turned off when you want
                    a broader range of results. Additionally, you can filter by
                    search results via the filter bar in the upper right corner
                    of the table, which allows you to filter information found
                    strictly within the table.
                  </p>
                </div>

                {/*Question/Answer*/}
                <div>
                  <div className="faq-question-q-box">Q.</div>
                  <h4 className="faq-question">
                    When will <i>KIPRA-chat</i> come out?
                  </h4>
                  <p className="faq-answer mb-4">
                    We hope this feature can be rolled out within the next
                    several months. Stay tuned!
                  </p>
                </div>
              </div>

              <div className="col-lg-5">
                {/*Question/Answer*/}
                <div>
                  <div className="faq-question-q-box">Q.</div>
                  <h4 className="faq-question" data-wow-delay=".1s">
                    What upcoming features does KIPRA have in store?
                  </h4>
                  <p className="faq-answer mb-4">
                    In the near future we plan to enable our medical device
                    product lineup with chatGPT-like experience. With{" "}
                    <i>KIPRA-chat</i>, you may "talk" to a collection of
                    documents (such as those around a specific topic) and get
                    the information from the prompt.
                  </p>
                </div>

                {/*Question/Answer*/}
                <div>
                  <div className="faq-question-q-box">Q.</div>
                  <h4 className="faq-question">
                    How can I suggest new features?
                  </h4>
                  <p className="faq-answer mb-4">
                    You can make feature and product suggestions using the get
                    in touch form below! We highly value and encourage feedback
                    and input from our growing community, as it plays an
                    integral role in our ongoing efforts to improve.
                  </p>
                </div>

                {/*Question/Answer*/}
                <div>
                  <div className="faq-question-q-box">Q.</div>
                  <h4 className="faq-question" data-wow-delay=".1s">
                    How are search results structured?
                  </h4>
                  <p className="faq-answer mb-4">
                    Search results are by default ordered by relevance. You can
                    view search highlights by clicking on the green button below
                    the issue/decision date. You can also view additional
                    information by clicking on the title, which displays both
                    FDA and Federal Register links, as well as other relevant
                    information.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faq;
