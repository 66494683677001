import { useState, useEffect } from "react";
import {
  InformationRow,
  SourceLink,
  HighlightText,
} from "../utils/HighlightUtils";

const DenovoDevice = ({ highlightData }) => (
  <>
    <InformationRow
      title="Denovo Number:"
      value={highlightData.denovo_num}
      highlightValue={highlightData.highlight.denovo_num}
    />
    <InformationRow
      title="Device Name:"
      value={highlightData.device_name}
      highlightValue={highlightData.highlight.device_name}
    />
    <InformationRow
      title="Device Classification Name:"
      value={highlightData.device_classification_name}
      highlightValue={highlightData.highlight.device_classification_name}
    />
    <InformationRow
      title="Regulation Number:"
      value={highlightData.regulation_number}
      highlightValue={highlightData.highlight.regulation_number}
    />
    <InformationRow
      title="Classification Product Code:"
      value={highlightData.classification_product_code}
      highlightValue={highlightData.highlight.classification_product_code}
    />
    <InformationRow
      title="Requester Company:"
      value={highlightData.requester}
      highlightValue={highlightData.highlight.requester}
    />
    <InformationRow
      title="Requester Contact:"
      value={highlightData.contact}
      highlightValue={highlightData.highlight.requester_contact}
    />
  </>
);

const _510kDevice = ({ highlightData }) => (
  <>
    <InformationRow
      title="510(k) Number:"
      value={highlightData.k_num}
      highlightValue={highlightData.highlight.k_num}
    />
    <InformationRow
      title="Device Name:"
      value={highlightData.device_name}
      highlightValue={highlightData.highlight.device_name}
    />
    <InformationRow
      title="Device Classification Name:"
      value={highlightData.device_classification_name}
      highlightValue={highlightData.highlight.device_classification_name}
    />
    <InformationRow
      title="Regulation Number:"
      value={highlightData.regulation_number}
      highlightValue={highlightData.highlight.regulation_number}
    />
    <InformationRow
      title="Classification Product Code:"
      value={highlightData.classification_product_code}
      highlightValue={highlightData.highlight.classification_product_code}
    />
    <InformationRow
      title="Applicant Company:"
      value={highlightData.applicant}
      highlightValue={highlightData.highlight.applicant}
    />
    <InformationRow
      title="Applicant Contact:"
      value={highlightData.applicant_contact}
      highlightValue={highlightData.highlight.applicant_contact}
    />
  </>
);

const PmaDevice = ({ highlightData }) => (
  <>
    <InformationRow
      title="PMA Number:"
      value={highlightData.full_pma_num}
      highlightValue={highlightData.highlight.full_pma_num}
    />
    <InformationRow
      title="Device:"
      value={highlightData.device}
      highlightValue={highlightData.highlight.device}
    />
    <InformationRow
      title="Generic Name:"
      value={highlightData.generic_name}
      highlightValue={highlightData.highlight.generic_name}
    />
    {highlightData.classification_name && (
      <InformationRow
        title="Classification Name:"
        value={highlightData.classification_name}
        highlightValue={highlightData.highlight.classification_name}
      />
    )}
    {highlightData.regulation_number && (
      <InformationRow
        title="Regulation Number:"
        value={highlightData.regulation_number}
        highlightValue={highlightData.highlight.regulation_number}
      />
    )}
    {highlightData.docket_number && (
      <InformationRow
        title="Docket Number:"
        value={highlightData.docket_number}
        highlightValue={highlightData.highlight.docket_number}
      />
    )}
    <InformationRow
      title="Product Code:"
      value={highlightData.product_code}
      highlightValue={highlightData.highlight.product_code}
    />
    <InformationRow
      title="Applicant Company:"
      value={highlightData.applicant}
      highlightValue={highlightData.highlight.applicant}
    />
  </>
);

const DenovoHighlightIuIfuText = ({ highlights }) => (
  <div>
    {highlights.indications_for_use && (
      <>
        <p className="mt-2">
          <strong>Indications For Use:</strong>
        </p>
        <HighlightText type={"indications_for_use"} highlights={highlights} />
      </>
    )}
    {highlights.intended_use && (
      <>
        <p className="mt-2">
          <strong>Intended Use:</strong>
        </p>
        <HighlightText type={"intended_use"} highlights={highlights} />
      </>
    )}
  </div>
);

const _510kHighlightIuIfuText = ({ highlights }) => (
  <div>
    {highlights.summary_statement_iu_indfu && (
      <>
        <p className="mt-2">
          <strong>510(k) Summary/Statement - Indications for Use</strong>
        </p>
        <HighlightText
          type={"summary_statement_iu_indfu"}
          highlights={highlights}
        />
      </>
    )}
    {highlights.decision_summary_iu && (
      <>
        <p className="mt-2">
          <strong>Decision Summary Intended Use:</strong>
        </p>
        <HighlightText type={"decision_summary_iu"} highlights={highlights} />
      </>
    )}
    {highlights.decision_summary_indfu && (
      <>
        <p className="mt-2">
          <strong>Decision Summary Indications For Use:</strong>
        </p>
        <HighlightText
          type={"decision_summary_indfu"}
          highlights={highlights}
        />
      </>
    )}
  </div>
);

function DenovoHighlightBody({ highlightData, statusHighlight }) {
  // Intalize State
  const [currTab, setCurrTab] = useState("Device");
  const setTab = (switchTab) => {
    setCurrTab(switchTab);
  };

  // Reset tab when toggling highlight
  useEffect(() => setCurrTab("Device"), [statusHighlight]);

  const NavLinks = ({ highlightData }) => (
    <ul className="nav nav-tabs nav-bordered nav-justified mb-3">
      <li className="nav-item">
        <a
          className={"nav-link" + (currTab === "Device" ? " active" : "")}
          onClick={() => setTab("Device")}
        >
          <i className="mdi mdi-subtitles-outline d-md-none d-block"></i>
          <span className="d-none d-md-block">Device</span>
        </a>
      </li>
      {(highlightData.highlight.intended_use ||
        highlightData.highlight.indications_for_use) && (
        <li className="nav-item">
          <a
            className={"nav-link" + (currTab === "IU / IFU" ? " active" : "")}
            onClick={() => setTab("IU / IFU")}
          >
            <i className="mdi mdi-subtitles-outline d-md-none d-block"></i>
            <span className="d-none d-md-block">IU / IFU</span>
          </a>
        </li>
      )}
      {highlightData.highlight.decision_summary_pdf_text && (
        <li className="nav-item">
          <a
            className={
              "nav-link" + (currTab === "Decision Summary" ? " active" : "")
            }
            onClick={() => setTab("Decision Summary")}
          >
            <i className="mdi mdi-subtitles-outline d-md-none d-block"></i>
            <span className="d-none d-md-block">Decision Summary</span>
          </a>
        </li>
      )}
      {highlightData.highlight.reclassification_order_pdf_text && (
        <li className="nav-item">
          <a
            className={
              "nav-link" +
              (currTab === "Reclassification Order" ? " active" : "")
            }
            onClick={() => setTab("Reclassification Order")}
          >
            <i className="mdi mdi-subtitles-outline d-md-none d-block"></i>
            <span className="d-none d-md-block">Reclassification Order</span>
          </a>
        </li>
      )}
    </ul>
  );

  return (
    <>
      <NavLinks highlightData={highlightData} />
      {currTab === "Device" && (
        <>
          <SourceLink linkText={"FDA"} link={highlightData.denovo_link} />{" "}
          <DenovoDevice highlightData={highlightData} />{" "}
        </>
      )}
      {currTab === "IU / IFU" && (
        <>
          <SourceLink
            linkText={"Decision Summary / Reclass. Order"}
            link={
              highlightData.decision_summary_pdf_link ||
              highlightData.reclassification_order_pdf_link
            }
          />
          <DenovoHighlightIuIfuText highlights={highlightData.highlight} />
        </>
      )}
      {currTab === "Decision Summary" && (
        <>
          <SourceLink
            linkText={"Decision Summary"}
            link={highlightData.decision_summary_pdf_link}
          />
          <HighlightText
            type={"decision_summary_pdf_text"}
            highlights={highlightData.highlight}
          />
        </>
      )}
      {currTab === "Reclassification Order" && (
        <>
          <SourceLink
            linkText={"Reclassification Order"}
            link={highlightData.reclassification_order_pdf_link}
          />
          <HighlightText
            type={"reclassification_order_pdf_text"}
            highlights={highlightData.highlight}
          />
        </>
      )}
    </>
  );
}

function _510kHighlightBody({ highlightData, statusHighlight }) {
  // Intalize State
  const [currTab, setCurrTab] = useState("Device");
  const setTab = (switchTab) => {
    setCurrTab(switchTab);
  };

  // Reset tab when toggling highlight
  useEffect(() => setCurrTab("Device"), [statusHighlight]);

  const NavLinks = ({ highlightData }) => (
    <ul className="nav nav-tabs nav-bordered nav-justified mb-3">
      <li className="nav-item">
        <a
          className={"nav-link" + (currTab === "Device" ? " active" : "")}
          onClick={() => setTab("Device")}
        >
          <i className="mdi mdi-subtitles-outline d-md-none d-block"></i>
          <span className="d-none d-md-block">Device</span>
        </a>
      </li>
      {(highlightData.highlight.summary_statement_iu_indfu ||
        highlightData.highlight.decision_summary_iu ||
        highlightData.highlight.decision_summary_indfu) && (
        <li className="nav-item">
          <a
            className={"nav-link" + (currTab === "IU / IFU" ? " active" : "")}
            onClick={() => setTab("IU / IFU")}
          >
            <i className="mdi mdi-subtitles-outline d-md-none d-block"></i>
            <span className="d-none d-md-block">IU / IFU</span>
          </a>
        </li>
      )}
      {highlightData.highlight.summary_pdf_text && (
        <li className="nav-item">
          <a
            className={"nav-link" + (currTab === "Summary" ? " active" : "")}
            onClick={() => setTab("Summary")}
          >
            <i className="mdi mdi-subtitles-outline d-md-none d-block"></i>
            <span className="d-none d-md-block">Summary</span>
          </a>
        </li>
      )}
      {highlightData.highlight.statement_pdf_text && (
        <li className="nav-item">
          <a
            className={"nav-link" + (currTab === "Statement" ? " active" : "")}
            onClick={() => setTab("Statement")}
          >
            <i className="mdi mdi-subtitles-outline d-md-none d-block"></i>
            <span className="d-none d-md-block">Statement</span>
          </a>
        </li>
      )}
      {highlightData.highlight.decision_summary_pdf_text && (
        <li className="nav-item">
          <a
            className={
              "nav-link" + (currTab === "Decision Summary" ? " active" : "")
            }
            onClick={() => setTab("Decision Summary")}
          >
            <i className="mdi mdi-subtitles-outline d-md-none d-block"></i>
            <span className="d-none d-md-block">Decision Summary</span>
          </a>
        </li>
      )}
    </ul>
  );

  return (
    <>
      <NavLinks highlightData={highlightData} />
      {currTab === "Device" && (
        <>
          <SourceLink linkText={"FDA"} link={highlightData.link} />{" "}
          <_510kDevice highlightData={highlightData} />{" "}
        </>
      )}
      {currTab === "IU / IFU" && (
        <>
          <SourceLink
            linkText={"Summary / Statement"}
            link={
              highlightData.link_510k_summary || highlightData.link_statement
            }
          />
          <_510kHighlightIuIfuText highlights={highlightData.highlight} />
        </>
      )}
      {currTab === "Summary" && (
        <>
          <SourceLink
            linkText={"Summary"}
            link={highlightData.link_510k_summary}
          />
          <HighlightText
            type={"summary_pdf_text"}
            highlights={highlightData.highlight}
          />
        </>
      )}
      {currTab === "Statement" && (
        <>
          <SourceLink
            linkText={"Statement"}
            link={highlightData.link_statement}
          />
          <HighlightText
            type={"statement_pdf_text"}
            highlights={highlightData.highlight}
          />
        </>
      )}
      {currTab === "Decision Summary" && (
        <>
          <SourceLink
            linkText={"Decision Summary"}
            link={highlightData.decision_summary_pdf_link}
          />
          <HighlightText
            type={"decision_summary_pdf_text"}
            highlights={highlightData.highlight}
          />
        </>
      )}
    </>
  );
}

function PmaHighlightBody({ highlightData, statusHighlight }) {
  // Intalize State
  const [currTab, setCurrTab] = useState("Device");
  const setTab = (switchTab) => {
    setCurrTab(switchTab);
  };

  // Reset tab when toggling highlight
  useEffect(() => setCurrTab("Device"), [statusHighlight]);

  const NavLinks = ({ highlightData }) => (
    <ul className="nav nav-tabs nav-bordered nav-justified mb-3">
      <li className="nav-item">
        <a
          className={"nav-link" + (currTab === "Device" ? " active" : "")}
          onClick={() => setTab("Device")}
        >
          <i className="mdi mdi-subtitles-outline d-md-none d-block"></i>
          <span className="d-none d-md-block">Device</span>
        </a>
      </li>
      {highlightData.highlight.approval_order_statement && (
        <li className="nav-item">
          <a
            className={
              "nav-link" + (currTab === "AO Statement" ? " active" : "")
            }
            onClick={() => setTab("AO Statement")}
          >
            <i className="mdi mdi-subtitles-outline d-md-none d-block"></i>
            <span className="d-none d-md-block">AO Statement</span>
          </a>
        </li>
      )}
      {highlightData.highlight.summary_pdf_text && (
        <li className="nav-item">
          <a
            className={"nav-link" + (currTab === "Summary" ? " active" : "")}
            onClick={() => setTab("Summary")}
          >
            <i className="mdi mdi-subtitles-outline d-md-none d-block"></i>
            <span className="d-none d-md-block">Summary</span>
          </a>
        </li>
      )}
      {highlightData.highlight.approval_order_pdf_text && (
        <li className="nav-item">
          <a
            className={
              "nav-link" + (currTab === "Approval Order" ? " active" : "")
            }
            onClick={() => setTab("Approval Order")}
          >
            <i className="mdi mdi-subtitles-outline d-md-none d-block"></i>
            <span className="d-none d-md-block">Approval Order</span>
          </a>
        </li>
      )}
      {highlightData.highlight.review_memo_pdf_text && (
        <li className="nav-item">
          <a
            className={
              "nav-link" + (currTab === "Review Memo" ? " active" : "")
            }
            onClick={() => setTab("Review Memo")}
          >
            <i className="mdi mdi-subtitles-outline d-md-none d-block"></i>
            <span className="d-none d-md-block">Review Memo</span>
          </a>
        </li>
      )}
    </ul>
  );

  return (
    <>
      <NavLinks highlightData={highlightData} />
      {currTab === "Device" && (
        <>
          <SourceLink linkText={"FDA"} link={highlightData.link} />{" "}
          <PmaDevice highlightData={highlightData} />{" "}
        </>
      )}
      {currTab === "AO Statement" && (
        <>
          <SourceLink linkText={"FDA"} link={highlightData.link} />
          <HighlightText
            type={"approval_order_statement"}
            highlights={highlightData.highlight}
          />
        </>
      )}
      {currTab === "Summary" && (
        <>
          <SourceLink linkText={"Summary"} link={highlightData.summary_link} />
          <HighlightText
            type={"summary_pdf_text"}
            highlights={highlightData.highlight}
          />
        </>
      )}
      {currTab === "Approval Order" && (
        <>
          <SourceLink
            linkText={"Approval Order"}
            link={highlightData.approval_order_link}
          />
          <HighlightText
            type={"approval_order_pdf_text"}
            highlights={highlightData.highlight}
          />
        </>
      )}
      {currTab === "Review Memo" && (
        <>
          <SourceLink
            linkText={"Review Memo"}
            link={highlightData.review_memo_link}
          />
          <HighlightText
            type={"review_memo_pdf_text"}
            highlights={highlightData.highlight}
          />
        </>
      )}
    </>
  );
}

function HighlightBody({ highlightData, statusHighlight }) {
  if (highlightData.index_name === "fda_denovo") {
    return (
      <DenovoHighlightBody
        highlightData={highlightData}
        statusHighlight={statusHighlight}
      />
    );
  } else if (highlightData.index_name === "fda_510k") {
    return (
      <_510kHighlightBody
        highlightData={highlightData}
        statusHighlight={statusHighlight}
      />
    );
  } else if (highlightData.index_name === "fda_pma") {
    return (
      <PmaHighlightBody
        highlightData={highlightData}
        statusHighlight={statusHighlight}
      />
    );
  }
}

export default HighlightBody;
