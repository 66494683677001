import { useState } from "react";
import Forms from "../../utils/filterBarComponents/Forms.jsx";
import { subForm } from "../../utils/filterBarComponents/consts.js";

const panelOptions = [
  ["Anesthesiology", "Anesthesiology"],
  ["Cardiovascular", "Cardiovascular"],
  ["Clinical Chemistry", "Clinical Chemistry"],
  ["Dental", "Dental"],
  ["Ear Nose & Throat", "Ear Nose & Throat"],
  ["Gastroenterology/Urology", "Gastroenterology/Urology"],
  ["General & Plastic Surgery", "General & Plastic Surgery"],
  ["General Hospital", "General Hospital"],
  ["Hematology", "Hematology"],
  ["Immunology", "Immunology"],
  ["Microbiology", "Microbiology"],
  ["Molecular Genetics", "Molecular Genetics"],
  ["Neurology", "Neurology"],
  ["Obstetrics/Gynecology", "Obstetrics/Gynecology"],
  ["Ophthalmic", "Ophthalmic"],
  ["Orthopedic", "Orthopedic"],
  ["Pathology", "Pathology"],
  ["Physical Medicine", "Physical Medicine"],
  ["Radiology", "Radiology"],
  ["Toxicology", "Toxicology"],
];

function PanelsFilter(props) {
  const [panelsFilter, setPanelsFilter] = useState(false);

  const inPanels = (field) => {
    return props.filterOptions.panels.includes(field);
  };

  const panelsFilterUpdate = (field) => {
    if (inPanels(field)) {
      props.setFilterOptions((prevState) => ({
        ...prevState,
        panels: prevState.panels.filter((item) => item !== field),
      }));
    } else {
      props.setFilterOptions((prevState) => ({
        ...prevState,
        panels: [...prevState.panels, field],
      }));
    }
  };

  const masterInFilters = (typeField) => {
    if (typeField === "panels") {
      return panelOptions.some((curr) =>
        props.filterOptions.panels.includes(curr[0])
      );
    } else {
      return false;
    }
  };

  const updateFilterOptions = (fieldsToUpdate, checked, optionsKey) => {
    if (checked) {
      const existingFieldsSet = new Set(props.filterOptions[optionsKey]);
      const toAdd = fieldsToUpdate
        .filter(([fieldName]) => !existingFieldsSet.has(fieldName))
        .map(([fieldName]) => fieldName);

      props.setFilterOptions((prevState) => ({
        ...prevState,
        [optionsKey]: [...prevState[optionsKey], ...toAdd],
      }));
    } else {
      const fieldsSet = new Set(fieldsToUpdate.map(([fieldName]) => fieldName));
      const newFields = props.filterOptions[optionsKey].filter(
        (fieldName) => !fieldsSet.has(fieldName)
      );

      props.setFilterOptions((prevState) => ({
        ...prevState,
        [optionsKey]: newFields,
      }));
    }
  };

  const masterFilterUpdate = (checked, typeField) => {
    switch (typeField) {
      case "panels":
        updateFilterOptions(panelOptions, checked, "panels");
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="card-header">
        <h5>
          <a
            className={"custom-accordion-title d-block py-1"}
            onClick={() => setPanelsFilter(!panelsFilter)}
          >
            Panel/Committee
            <i
              className={
                "mdi accordion-arrow mdi-chevron-" +
                (panelsFilter ? "up" : "down")
              }
            />
          </a>
        </h5>
      </div>
      <div className={"collapse-custom" + (panelsFilter ? " show" : "")}>
        <div className="card-body">
          <div className="form-check form-checkbox-primary mb-2">
            <input
              type="checkbox"
              className="form-check-input"
              checked={masterInFilters("panels")}
              onChange={(e) => masterFilterUpdate(e.target.checked, "panels")}
            />
            <label className="form-check-label">All</label>
          </div>
          <Forms
            inFilter={inPanels}
            filterUpdate={panelsFilterUpdate}
            fields={panelOptions}
            type={subForm}
          />
        </div>
      </div>
    </>
  );
}

export default PanelsFilter;
