import { useState } from "react";
import Forms from "./Forms.jsx";
import { primaryForm } from "./consts.js";

function BasicOptionsFilter({
  filterOptions,
  setFilterOptions,
  title,
  options,
  optionsType,
}) {
  const [optionsFilter, setOptionsFilter] = useState(false);
  const inOptions = (field) => {
    return filterOptions[optionsType].includes(field);
  };
  const optioinsUpdate = (field) => {
    if (inOptions(field)) {
      setFilterOptions((prevState) => ({
        ...prevState,
        [optionsType]: prevState[optionsType].filter((item) => item !== field),
      }));
    } else {
      setFilterOptions((prevState) => ({
        ...prevState,
        [optionsType]: [...prevState[optionsType], field],
      }));
    }
  };

  return (
    <>
      <div className="card-header">
        <h5>
          <a
            className={"custom-accordion-title d-block py-1"}
            onClick={() => setOptionsFilter(!optionsFilter)}
          >
            {title}
            <i
              className={
                "mdi accordion-arrow mdi-chevron-" +
                (optionsFilter ? "up" : "down")
              }
            />
          </a>
        </h5>
      </div>
      <div className={"collapse-custom" + (optionsFilter ? " show" : "")}>
        <div className="card-body">
          <Forms
            inFilter={inOptions}
            filterUpdate={optioinsUpdate}
            fields={options}
            type={primaryForm}
          />
        </div>
      </div>
    </>
  );
}

export default BasicOptionsFilter;
