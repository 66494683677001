import "../../../assets/css/icons.min.css";
import "../../../assets/css/app-creative.min.css";
import NavBar from "../../components/NavBar";
import DeviceBody from "../../components/device/DeviceBody";

function Pma() {
  return (
    <div>
      <NavBar />
      <DeviceBody deviceType={"pma"} />
    </div>
  );
}

export default Pma;
