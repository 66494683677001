import DenovoFilter from "./deviceFilterBarComponents/denovo/DenovoFilter";
import DateFilter from "./deviceFilterBarComponents/DateFilter";
import DenovoType from "./deviceFilterBarComponents/denovo/DenovoType";
import PanelsFilter from "./deviceFilterBarComponents/PanelsFilter";
import Center from "./deviceFilterBarComponents/CenterFilter";
import _510kFilter from "./deviceFilterBarComponents/_510k/_510kFilter";
import _510kDecisionType from "./deviceFilterBarComponents/_510k/_510kDecisionType";
import PmaFilter from "./deviceFilterBarComponents/pma/PmaFilter";
import PmaSupplement from "./deviceFilterBarComponents/pma/PmaSupplement";

export default function FilterBody({ type, filterOptions, setFilterOptions }) {
  if (type === "fda_denovo" || type === "denovo") {
    return (
      <>
        <DenovoFilter
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
        />
        <DateFilter
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
        />
        <DenovoType
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
        />
        <PanelsFilter
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
        />
        <Center
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
        />
      </>
    );
  } else if (type === "fda_510k" || type === "510k") {
    return (
      <>
        <_510kFilter
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
        />
        <DateFilter
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
        />
        <_510kDecisionType
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
        />
        <PanelsFilter
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
        />
        <Center
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
        />
      </>
    );
  } else if (type === "fda_pma" || type === "pma") {
    return (
      <>
        <PmaFilter
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
        />
        <DateFilter
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
        />
        <PmaSupplement
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
        />
        <PanelsFilter
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
        />
        <Center
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
        />
      </>
    );
  }
}
