import { useState } from "react";
import Forms from "../../../utils/filterBarComponents/Forms.jsx";
import { primaryForm } from "../../../utils/filterBarComponents/consts.js";

const typeFields = [
  ["Direct", "Direct"],
  ["Post-NSE", "Post-NSE"],
];

function DenovoType(props) {
  const [typeFilter, setTypeFilter] = useState(false);
  const inTypes = (field) => {
    return props.filterOptions.type.includes(field);
  };
  const typeUpdate = (field) => {
    if (inTypes(field)) {
      props.setFilterOptions((prevState) => ({
        ...prevState,
        type: prevState.type.filter((item) => item !== field),
      }));
    } else {
      props.setFilterOptions((prevState) => ({
        ...prevState,
        type: [...prevState.type, field],
      }));
    }
  };

  return (
    <>
      <div className="card-header">
        <h5>
          <a
            className={"custom-accordion-title d-block py-1"}
            onClick={() => setTypeFilter(!typeFilter)}
          >
            Type
            <i
              className={
                "mdi accordion-arrow mdi-chevron-" +
                (typeFilter ? "up" : "down")
              }
            />
          </a>
        </h5>
      </div>
      <div className={"collapse-custom" + (typeFilter ? " show" : "")}>
        <div className="card-body">
          <Forms
            inFilter={inTypes}
            filterUpdate={typeUpdate}
            fields={typeFields}
            type={primaryForm}
          />
        </div>
      </div>
    </>
  );
}

export default DenovoType;
