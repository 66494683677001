import BasicDatePicker from "./DatePicker";
import { useState } from "react";

export default function DateFilter({
  filterOptions,
  setFilterOptions,
  title,
  dateKeyStart,
  dateKeyEnd,
}) {
  const [dateFilter, setDateFilter] = useState(false);

  return (
    <>
      <div className="card-header">
        <h5>
          <a
            className={"custom-accordion-title d-block py-1"}
            onClick={() => setDateFilter(!dateFilter)}
          >
            {title}
            <i
              className={
                "mdi accordion-arrow mdi-chevron-" +
                (dateFilter ? "up" : "down")
              }
            />
          </a>
        </h5>
      </div>
      <div className={"collapse-custom" + (dateFilter ? " show" : "")}>
        <div className="card-body">
          <BasicDatePicker
            label="Start Date"
            currDate={filterOptions[dateKeyStart]}
            setDate={(date) =>
              setFilterOptions((prevState) => ({
                ...prevState,
                [dateKeyStart]: date,
              }))
            }
          />
          <BasicDatePicker
            label="End Date"
            currDate={filterOptions[dateKeyEnd]}
            setDate={(date) =>
              setFilterOptions((prevState) => ({
                ...prevState,
                [dateKeyEnd]: date,
              }))
            }
          />
        </div>
      </div>
    </>
  );
}
