import NavBar from "./components/NavBar";
import Featured from "./components/Featured";
import Features from "./components/Features";
import Faq from "./components/Faq";
import Footer from "./components/Footer";
import "../../assets/css/kipra/test.css";
import "../../assets/css/kipra/icons.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app-creative.min.css";

function Landing() {
  return (
    <div>
      <div className="first">
        <div className="first">
          <NavBar />
          <Featured />
        </div>
        {/*Background Credits Open Source: https://wweb.dev/resources, Author: Vincenius*/}
        <div className="background">
          <ul>
            {Array.from({ length: 24 }).map((_, idx) => (
              <li key={idx}></li>
            ))}
          </ul>
        </div>
      </div>
      <Features />
      <Faq />
      <Footer />
    </div>
  );
}

export default Landing;
