function Featured() {
  return (
    <section name="featured">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-8 offset-md-2">
            <div className="mt-md-6">
              <h1 className="text-white text-center fw-normal mb-4 hero-title">
                Knowledge Is Power - Regulatory Affairs
              </h1>
              <p className="mb-4 font-16 text-white text-center">
                <span
                  style={{
                    fontSize: "1.5em",
                    fontWeight: "bold",
                    letterSpacing: "0.15em",
                    color: "lightgray",
                  }}
                >
                  KIPRA
                </span>{" "}
                is a cutting-edge platform for regulatory affairs knowledge
                sharing that seeks to simplify the time-consuming research
                process by acting as an all-in-one solution for all your
                regulatory intelligence needs.
              </p>
              <a
                href={`kipra.ai`}
                className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-8 offset-sm-2 col-6 offset-3 btn btn-primary align-center"
              >
                Try KIPRA Lite 510(k) Now
                <i className="mdi mdi-arrow-right ms-1"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center justify-content-center mt-4 pt-4 pb-4">
          <div className="col-lg-2 col-md-3 col-sm-6 col-8 text-center border border-light pt-4 pb-4 pt-sm-3 pb-sm-3 mb-2 rounded mx-4">
            <h2 className="mb-3 text-light">
              <i className="dripicons-article"></i>
            </h2>
            <h3 className="text-light">Guidance</h3>
            <a
              href="/p/resource/USFDA/guidance"
              className="btn btn-outline-success rounded-pill mt-4 mb-4"
            >
              <i className="mdi mdi-magnify"></i> Guidance
            </a>
          </div>

          <div className="col-lg-2 col-md-3 col-sm-6 col-8 text-center border border-light pt-4 pb-4 pt-sm-3 pb-sm-3 mb-2 rounded mx-4">
            <h2 className="mb-3 text-light">
              <i className="mdi mdi-shield-check-outline"></i>
            </h2>
            <h3 className="text-light">510(K)</h3>
            <a
              href="/p/device/USFDA/510k"
              className="btn btn-outline-success rounded-pill mt-4 mb-4"
            >
              <i className="mdi mdi-magnify"></i> 510(k)
            </a>
          </div>

          <div className="col-lg-2 col-md-3 col-sm-6 col-8 text-center border border-light pt-4 pb-4 pt-sm-3 pb-sm-3 mb-2 rounded mx-4">
            <h2 className="mb-3 text-light">
              <i className="mdi mdi-shield-check-outline"></i>
            </h2>
            <h3 className="text-light">De Novo</h3>
            <a
              href="/p/device/USFDA/denovo"
              className="btn btn-outline-success rounded-pill mt-4 mb-4"
            >
              <i className="mdi mdi-magnify"></i> De Novo
            </a>
          </div>

          <div className="col-lg-2 col-md-3 col-sm-6 col-8 text-center border border-light pt-4 pb-4 pt-sm-3 pb-sm-3 mb-2 rounded mx-4">
            <h2 className="mb-3 text-light">
              <i className="mdi mdi-shield-check-outline"></i>
            </h2>
            <h3 className="text-light">PMA</h3>
            <a
              href="/p/device/USFDA/PMA"
              className="btn btn-outline-success rounded-pill mt-4 mb-4"
            >
              <i className="mdi mdi-magnify"></i> PMA
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Featured;
