import { convertDatetime } from "../utils/utils.js";

function convertDates(filterOptions) {
  filterOptions.issue_start_date &&
    filterOptions.issue_start_date.format("YYYY-MM-DD");
  filterOptions.issue_end_date &&
    filterOptions.issue_end_date.format("YYYY-MM-DD");

  return filterOptions;
}

const getSearcnData = async (filterOptions) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(convertDates(filterOptions)),
    };
    const response = await fetch(
      "https://kipra.ai/flask_app/search",
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

async function searchGuidanceRows(filterOptions) {
  const guidanceData = await getSearcnData(filterOptions);

  // Error has occured or no results were found
  if ("msg" in guidanceData) {
    return guidanceData.msg;
  }

  let rows = [];
  guidanceData.forEach((guidance) => {
    const currRow = {
      id: guidance.id,
      guidanceTitle: guidance.guidance_title,
      issueDate: convertDatetime(guidance.issue_date),
      fdaOrganization: guidance.fda_organization,
      topic: guidance.topic,
      regulatedProducts: guidance.regulated_products,
      guidanceStatus: guidance.guidance_status,
      openForComment: guidance.open_for_comment,
      searchRowData: guidance,
    };
    rows.push(currRow);
  });

  return rows;
}

export const searchRowsCreate = async (type, filterOptions) => {
  if (type === "fda_guidance" || type === "guidance") {
    return searchGuidanceRows(filterOptions);
  }
  return [];
};
