import GuidanceProfile from "./GuidanceProfile.jsx";
import FilterBar from "../FilterBar.jsx";
import SearchBar from "../SearchBar.jsx";
import Highlight from "../Highlight.jsx";
import { columnsCreate, rowsCreate } from "../database/resourceGridData.js";
import React, { useState, useRef, useEffect } from "react";
import { getFilterOptions } from "./resourceFilter.js";
import CustomDataGrid from "../CustomDataGrid.jsx";
import FilterBody from "./FilterBody.jsx";
import { searchRowsCreate } from "../database/resourceSearch.js";
import HighlightBody from "./HighlightBody.jsx";

const columnsVis = (resourceType) => {
  if (resourceType === "fda_guidance" || resourceType === "guidance") {
    return {
      openForComment: false,
    };
  }
};

const highlightTitle = (highlightData) => {
  if (highlightData.index_name === "fda_guidance") {
    return "Guidance";
  }
};

function ResourceBody({ resourceType }) {
  // Profile
  const [statusProfile, setStatusProfile] = useState(false);
  const currProfile = useRef();

  const setProfile = (resourceNum) => {
    setStatusProfile(true);
    currProfile.current = resourceNum;
  };

  const setStatusFalseProfile = () => {
    setStatusProfile(false);
  };

  // Highlight
  const [statusHighlight, setStatusHighlight] = useState(false);
  const currHighlight = useRef();

  const setHighlight = (highlightData) => {
    setStatusHighlight(true);
    currHighlight.current = highlightData;
  };

  const setStatusFalseHighlight = () => {
    setStatusHighlight(false);
  };

  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const fetchedRows = await rowsCreate(resourceType);
        setRows((prevRows) =>
          JSON.stringify(prevRows) !== JSON.stringify(fetchedRows)
            ? fetchedRows
            : prevRows
        );
      } catch (error) {
        console.error("Failed to fetch rows:", error);
        setRows([]);
      }
    })();

    const newColumns = columnsCreate(resourceType, setProfile, setHighlight);
    setColumns((prevColumns) =>
      JSON.stringify(prevColumns) !== JSON.stringify(newColumns)
        ? newColumns
        : prevColumns
    );
  }, [resourceType]);

  // Set Search Filter Object
  const [statusFilter, setStatusFilter] = useState(false);
  const [filterOptions, setFilterOptions] = useState(
    getFilterOptions(resourceType)
  );
  const setStatusFalseFilter = () => {
    setStatusFilter(false);
  };

  // Loading
  const [isLoading, setLoading] = useState(false);

  return (
    <div className="wrapper">
      <div className="content-page-custom">
        <div className="container-fluid table-body-height">
          <SearchBar
            type={resourceType}
            filterOptions={filterOptions}
            setStatusFilter={setStatusFilter}
            setFilterOptions={setFilterOptions}
            setRows={setRows}
            setLoading={setLoading}
            searchRowsCreate={searchRowsCreate}
            rowsCreate={rowsCreate}
          />
          <div className="card border-muted border d-block table-body-height">
            <div className="card-body table-body-height">
              <Highlight
                statusHighlight={statusHighlight}
                highlightData={currHighlight.current}
                setStatusFalseHighlight={setStatusFalseHighlight}
                highlightTitle={highlightTitle}
                HighlightBody={HighlightBody}
              />
              <FilterBar
                status={statusFilter ? "show" : ""}
                setStatusFalseFilter={setStatusFalseFilter}
                filterOptions={filterOptions}
                setFilterOptions={setFilterOptions}
                type={resourceType}
                FilterBody={FilterBody}
              />
              {resourceType === "guidance" && (
                <GuidanceProfile
                  status={statusProfile ? "show" : ""}
                  id={currProfile.current}
                  onStateChange={setStatusFalseProfile}
                  resourceType={resourceType}
                />
              )}
              <CustomDataGrid
                isLoading={isLoading}
                rows={rows}
                columns={columns}
                vis={columnsVis(resourceType)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResourceBody;
