import { buildProCodeLink, buildRegLink } from "../utils/utils.js";
import {
  TextLink,
  SubField,
  SubWithField,
  SubFieldLink,
  ReviewTimeline,
} from "../utils/ProfileUtils.jsx";

function DenovoProfile({ profileOn, device }) {
  if (profileOn) {
    return (
      <div className={"tab-pane fade active" + (profileOn ? " show" : "")}>
        <div className="mb-1">
          <TextLink
            linkUrl={device.decision_summary_pdf_link}
            title={"Decision Summary"}
            align={""}
          />
          <TextLink
            linkUrl={device.reclassification_order_pdf_link}
            title={"Reclassification Order"}
            align={"ms-sm-1"}
          />
        </div>
        {device.knumber && (
          <SubField titleText={"510(k) Number:"} dataText={device.knumber} />
        )}
        <SubField
          titleText={"Device Classification Name:"}
          dataText={device.device_classification_name}
        />
        <SubField titleText={"Requester:"} dataText={device.requester} />
        <SubField titleText={"Contact:"} dataText={device.contact} />
        <SubFieldLink
          titleText={"Regulation Number:"}
          dataText={device.regulation_number}
          buildLink={buildRegLink}
        />
        <SubFieldLink
          titleText={"Classification Product Code:"}
          dataText={device.classification_product_code}
          buildLink={buildProCodeLink}
        />
        <ReviewTimeline
          date_received={device.date_received}
          decision_date={device.decision_date}
          num_days_approve={device.num_days_approve}
        />
        <SubField titleText={"Decision:"} dataText={device.decision} />
        <SubField
          titleText={"Classification Advisory Committee:"}
          dataText={device.classification_advisory_committee}
        />
        <SubField
          titleText={"Review Advisory Committee:"}
          dataText={device.review_advisory_committee}
        />
        <SubField titleText={"Type:"} dataText={device.type} />
        <hr />
        <>
          <div className="mb-1">
            <p>
              <strong>Requester Details:</strong>
            </p>
            <span>{device.requester}</span>
            {device.requester_address_1 && (
              <>
                <br />
                <span>{device.requester_address_1}</span>
              </>
            )}
            {device.requester_address_2 && (
              <>
                <br />
                <span>{device.requester_address_2}</span>
              </>
            )}
            <br />
            <span>
              {device.requester_city},{" "}
              {device.requester_state_code
                ? device.requester_state_code
                : device.requester_country_code}{" "}
              {device.requester_zipcode}
            </span>
          </div>
        </>
      </div>
    );
  } else {
    return (
      <div className={"tab-pane fade active" + (profileOn ? "" : " show")}>
        <SubField
          titleText={"Indications for Use:"}
          dataText={device.indications_for_use}
        />
        <SubField titleText={"Intended Use:"} dataText={device.intended_use} />
      </div>
    );
  }
}

function _510kProfile({ profileOn, device }) {
  if (profileOn) {
    return (
      <div className={"tab-pane fade active" + (profileOn ? " show" : "")}>
        <div className="mb-1">
          <TextLink
            linkUrl={device.link_510k_summary || device.link_statement}
            title={"Summary/Statement"}
            align={""}
          />
          <TextLink
            linkUrl={device.link_decision_summary}
            title={"Decision Summary"}
            align={"ms-sm-1"}
          />
        </div>

        {device.link_device_classification_name ? (
          <SubWithField
            titleText={"Device Classification Name:"}
            dataText={device.device_classification_name}
            link={device.link_device_classification_name}
          />
        ) : (
          <SubField
            titleText={"Device Classification Name:"}
            dataText={device.device_classification_name}
          />
        )}
        {device.tradename && (
          <SubField titleText={"Tradename:"} dataText={device.tradename} />
        )}
        <SubField titleText={"Applicant:"} dataText={device.applicant} />
        <SubField
          titleText={"Applicant Contact:"}
          dataText={device.applicant_contact}
        />
        <SubField
          titleText={"Correspondent:"}
          dataText={device.correspondent}
        />
        <SubField
          titleText={"Correspondent Contact:"}
          dataText={device.correspondent_contact}
        />
        <SubFieldLink
          titleText={"Regulation Number:"}
          dataText={device.regulation_number}
          buildLink={buildRegLink}
        />
        <SubFieldLink
          titleText={"Classification Product Code:"}
          dataText={device.classification_product_code}
          buildLink={buildProCodeLink}
        />
        {device.secondary_product_code && (
          <SubFieldLink
            titleText={"Secondary Product Code:"}
            dataText={device.secondary_product_code}
            buildLink={buildProCodeLink}
          />
        )}
        {device.subsequent_product_code && (
          <SubFieldLink
            titleText={"Subsequent Product Code:"}
            dataText={device.subsequent_product_code}
            buildLink={buildProCodeLink}
          />
        )}
        <ReviewTimeline
          date_received={device.date_received}
          decision_date={device.decision_date}
          num_days_approve={device.num_days_approve}
        />
        <SubField titleText={"Decision:"} dataText={device.decision} />
        {device.classification_advisory_committee ? (
          <SubField
            titleText={"Classification Advisory Committee:"}
            dataText={device.classification_advisory_committee}
          />
        ) : (
          <SubField
            titleText={"Review Panel:"}
            dataText={device.review_panel}
          />
        )}
        {device.review_advisory_committee ? (
          <SubField
            titleText={"Review Advisory Committee:"}
            dataText={device.review_advisory_committee}
          />
        ) : (
          <SubField
            titleText={"Regulation Medical Specialty:"}
            dataText={device.regulation_medical_specialty}
          />
        )}
        <SubField titleText={"Type:"} dataText={device.type} />
      </div>
    );
  } else {
    return (
      <div className={"tab-pane fade active" + (profileOn ? "" : " show")}>
        <SubField
          titleText={"Intended Use/Indications For Use:"}
          dataText={device.summary_statement_iu_indfu}
        />
        {device.decision_summary_iu && (
          <SubField
            titleText={"Decision Summary - Intended Use"}
            dataText={device.decision_summary_iu}
          />
        )}
        {device.decision_summary_indfu && (
          <SubField
            titleText={"Decision Summary - Indications For Use"}
            dataText={device.decision_summary_indfu}
          />
        )}
      </div>
    );
  }
}

function PmaProfile({ profileOn, device }) {
  if (profileOn) {
    return (
      <div className={"tab-pane fade active" + (profileOn ? " show" : "")}>
        <div className="mb-1">
          <TextLink
            linkUrl={device.summary_link}
            title={"Summary (SSED)"}
            align={""}
          />
          <TextLink
            linkUrl={device.approval_order_link}
            title={"Approval Order"}
            align={"ms-sm-1"}
          />
        </div>
        {device.review_memo_link && (
          <div className="mb-1">
            <TextLink
              linkUrl={device.review_memo_link}
              title={"Review Memo"}
              align={""}
            />
          </div>
        )}
        <SubField titleText={"Generic Name:"} dataText={device.generic_name} />
        {device.classification_name_link && (
          <SubWithField
            titleText={"Classification Name:"}
            dataText={device.classification_name}
            link={device.classification_name_link}
          />
        )}
        <SubField titleText={"Applicant:"} dataText={device.applicant} />
        {device.regulation_number && (
          <SubFieldLink
            titleText={"Regulation Number:"}
            dataText={device.regulation_number}
            buildLink={buildRegLink}
          />
        )}
        {device.docket_number && (
          <SubField
            titleText={"Docket Number:"}
            dataText={device.docket_number}
          />
        )}
        <SubFieldLink
          titleText={"Product Code:"}
          dataText={device.product_code.split(";")[0]}
          buildLink={buildProCodeLink}
        />
        <ReviewTimeline
          date_received={device.date_received}
          decision_date={device.decision_date}
          num_days_approve={device.num_days_approve}
        />
        <SubField
          titleText={"Advisory Committee:"}
          dataText={device.advisory_committee}
        />
        <SubField
          titleText={"Supplement Type:"}
          dataText={device.supplement_type || "Original"}
        />
        {device.supplement_reason && (
          <SubField
            titleText={"Supplement Reason:"}
            dataText={device.supplement_reason}
          />
        )}
        <SubField
          titleText={"Expedited Review Granted:"}
          dataText={device.expedited_review_granted}
        />
        <SubField
          titleText={"Combination Product"}
          dataText={device.combination_product}
        />
      </div>
    );
  } else {
    return (
      <div className={"tab-pane fade active" + (profileOn ? "" : " show")}>
        <SubField
          titleText={"Approval Order Statement:"}
          dataText={device.approval_order_statement}
        />
      </div>
    );
  }
}

export { DenovoProfile, _510kProfile, PmaProfile };
