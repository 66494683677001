import { convertDatetime } from "../utils/utils.js";

function convertDates(filterOptions) {
  filterOptions.dec_start_date.format("YYYY-MM-DD");
  filterOptions.dec_end_date.format("YYYY-MM-DD");
  filterOptions.rec_start_date.format("YYYY-MM-DD");
  filterOptions.rec_end_date.format("YYYY-MM-DD");

  return filterOptions;
}

const getSearcnData = async (filterOptions) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(convertDates(filterOptions)),
    };
    const response = await fetch(
      "https://kipra.ai/flask_app/search",
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

async function searchDenovoRows(filterOptions) {
  const denovoData = await getSearcnData(filterOptions);

  // Error has occured or no results were found
  if ("msg" in denovoData) {
    return denovoData.msg;
  }

  let rows = [];
  denovoData.forEach((denovo) => {
    const currRow = {
      id: denovo.denovo_num,
      deNovoNumber: denovo.denovo_num,
      deviceName: denovo.device_name,
      applicant: denovo.requester,
      classificationCommittee: denovo.classification_advisory_committee,
      reviewAdvisoryCommittee: denovo.review_advisory_committee,
      dateReceived: convertDatetime(denovo.date_received),
      decisionDate: convertDatetime(denovo.decision_date),
      numDaysApprove: denovo.num_days_approve,
      proCode: denovo.classification_product_code,
      regulationNumber: denovo.regulation_number,
      deviceClassificationName: denovo.device_classification_name,
      decision: denovo.decision,
      type: denovo.type,
      searchRowData: denovo,
    };
    rows.push(currRow);
  });

  return rows;
}

async function search510kRows(filterOptions) {
  const _510kData = await getSearcnData(filterOptions);

  // Error has occured or no results were found
  if ("msg" in _510kData) {
    return _510kData.msg;
  }

  let rows = [];
  _510kData.forEach((_510k) => {
    const currRow = {
      id: _510k.k_num,
      _510kNumber: _510k.k_num,
      deviceName: _510k.device_name,
      applicant: _510k.applicant,
      correspondent: _510k.correspondent,
      reviewPanel: _510k.review_panel || _510k.review_advisory_committee,
      regulationMedicalSpeciality: _510k.regulation_medical_specialty,
      dateReceived: convertDatetime(_510k.date_received),
      decisionDate: convertDatetime(_510k.decision_date),
      numDaysApprove: _510k.num_days_approve,
      proCode: _510k.classification_product_code,
      regulationNumber: _510k.regulation_number,
      deviceClassificationName: _510k.device_classification_name,
      decision: _510k.decision,
      type: _510k.type,
      combinationProduct: _510k.combination_product,
      thirdPartyReview: _510k.reviewed_by_third_party,
      searchRowData: _510k,
    };
    rows.push(currRow);
  });

  return rows;
}

async function searchPmaRows(filterOptions) {
  const pmaData = await getSearcnData(filterOptions);

  // Error has occured or no results were found
  if ("msg" in pmaData) {
    return pmaData.msg;
  }

  const fixFullPmaNumber = (full_pma_num) => {
    full_pma_num = full_pma_num.replace(" ", "");
    return full_pma_num.replace("S", "/S");
  };

  let rows = [];
  pmaData.forEach((pma) => {
    const currRow = {
      id: pma.full_pma_num,
      fullPmaNumber: fixFullPmaNumber(pma.full_pma_num),
      pmaNumber: pma.pma_number,
      supplementNumber: pma.supplement_number,
      device: pma.device,
      genericName: pma.generic_name,
      applicant: pma.applicant,
      advisoryCommittee: pma.advisory_committee,
      dateReceived: convertDatetime(pma.date_received),
      decisionDate: convertDatetime(pma.decision_date),
      numDaysApprove: pma.num_days_approve,
      proCode: pma.product_code,
      regulationNumber: pma.regulation_number || "N/A",
      supplementType: pma.supplement_type || "Original",
      expeditedReviewGranted: pma.expedited_review_granted,
      combinationProduct: pma.combination_product,
      searchRowData: pma,
    };
    rows.push(currRow);
  });

  return rows;
}

export const searchRowsCreate = async (type, filterOptions) => {
  if (type === "fda_denovo" || type === "denovo") {
    return searchDenovoRows(filterOptions);
  } else if (type === "fda_510k" || type === "510k") {
    return search510kRows(filterOptions);
  } else if (type === "fda_pma" || type === "pma") {
    return searchPmaRows(filterOptions);
  }
  return [];
};
