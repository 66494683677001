import logo from "../../../assets/images/logo-kipra/kipra-low-resolution-logo-color-on-transparent-background.png";

function NavBar() {
  return (
    <nav className="navbar navbar-expand-lg py-lg-3 navbar-dark">
      <div className="container">
        {/*logo*/}
        <a href="https://kipra.ai" className="navbar-brand me-lg-5">
          <img src={logo} alt="" className="logo-dark" height="72" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="mdi mdi-menu"></i>
        </button>

        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          {/*menu*/}
          <ul className="navbar-nav me-auto align-items-center">
            <li className="nav-item mx-lg-1">
              <a className="nav-link active" href="#main">
                Home
              </a>
            </li>
            <li className="nav-item mx-lg-1">
              <a className="nav-link" href="#features">
                Features
              </a>
            </li>
            <li className="nav-item mx-lg-1">
              <a className="nav-link" href="#faq">
                FAQs
              </a>
            </li>
            <li className="nav-item mx-lg-1">
              <a className="nav-link" href="#contactUs">
                Contact Us
              </a>
            </li>
            <li className="nav-item mx-lg-1">
              <a
                className="nav-link "
                href="https://www.linkedin.com/company/kipra/"
              >
                <div className="avatar-xs">
                  <span className="avatar-title bg-primary rounded-circle">
                    {" "}
                    <i className="mdi mdi-linkedin"></i>{" "}
                  </span>
                </div>
              </a>
            </li>
          </ul>

          <ul className="navbar-nav ms-auto align-items-center">
            <li className="nav-item me-0">
              <a href="kipra.ai" className="nav-link d-lg-none">
                KIPRA Lite
              </a>
              <a
                href="kipra.ai"
                className="btn btn-sm btn-success rounded-pill d-none d-lg-inline-flex mx-4"
              >
                <i className="mdi mdi-domino-mask me-2 "></i>KIPRA Lite
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
