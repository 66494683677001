import { useState } from "react";
import Forms from "../../../utils/filterBarComponents/Forms.jsx";
import { subForm } from "../../../utils/filterBarComponents/consts.js";

const typeOptions = [
  ["Original", "Original"],
  ["Panel Track", "Panel Track"],
  ["Normal 180 Day Track", "Normal 180 Day Track"],
  ["Real-Time Process", "Real-Time Process"],
  ["Special Supplement", "Special Supplement"],
  ["Special (Immediate Track)", "Special (Immediate Track)"],
  ["30-Day Notice", "30-Day Notice"],
  ["30-Day Supplement", "30-Day Supplement"],
  ["135 Review Track For 30-Day Notice", "135 Review Track For 30-Day Notice"],
  ["Expedited PMA", "Expedited PMA"],
];

const additlTypes = [
  ["Normal 180 Day Track No User Fee", "Normal 180 Day Track No User Fee"],
  ["Real Time Process", "Real Time Process"],
  ["30 Day Supplement", "30 Day Supplement"],
  [
    "135 Day Track For 30 Day Notification",
    "135 Day Track For 30 Day Notification",
  ],
];

const reasonOptions = [
  [
    "Change Design/Components/Specifications/Material",
    "Change Design / Components / Specifications / Material",
  ],
  [
    "Process Change - Manufacturer/Sterilizer/Packager/Supplier",
    "Process Change - Manufacturer / Sterilizer / Packager / Supplier",
  ],
  [
    "Labeling Change - Indications/instructions/shelf Life/tradename",
    "Labeling Change - Indications/instructions/shelf Life/tradename",
  ],
  [
    "Location Change - Manufacturer/Sterilizer/Packager/Supplier",
    "Location Change - Manufacturer / Sterilizer / Packager / Supplier",
  ],
  ["Postapproval Study Protocol", "Postapproval Study Protocol"],
  ["Labeling Change - PAS", "Labeling Change - PAS"],
  ["Postapproval Study Protocol - OSB", "Postapproval Study Protocol - OSB"],
  ["Express GMP Supplement", "Express GMP Supplement"],
  ["Other", "Other"],
];

function PmaSupplement(props) {
  const [supplementFilter, setSupplementFilter] = useState(false);

  const inType = (field) => {
    return props.filterOptions.supplement_type.includes(field);
  };

  const typeFilterUpdate = (field) => {
    if (field === "Normal 180 Day Track") {
      if (inType(field)) {
        props.setFilterOptions((prevState) => ({
          ...prevState,
          supplement_type: prevState.supplement_type.filter(
            (item) =>
              item !== field && item !== "Normal 180 Day Track No User Fee"
          ),
        }));
      } else {
        props.setFilterOptions((prevState) => ({
          ...prevState,
          supplement_type: [
            ...prevState.supplement_type,
            field,
            "Normal 180 Day Track No User Fee",
          ],
        }));
      }
    } else if (field === "Real-Time Process") {
      if (inType(field)) {
        props.setFilterOptions((prevState) => ({
          ...prevState,
          supplement_type: prevState.supplement_type.filter(
            (item) => item !== field && item !== "Real Time Process"
          ),
        }));
      } else {
        props.setFilterOptions((prevState) => ({
          ...prevState,
          supplement_type: [
            ...prevState.supplement_type,
            field,
            "Real Time Process",
          ],
        }));
      }
    } else if (field === "30-Day Supplement") {
      if (inType(field)) {
        props.setFilterOptions((prevState) => ({
          ...prevState,
          supplement_type: prevState.supplement_type.filter(
            (item) => item !== field && item !== "30 Day Supplement"
          ),
        }));
      } else {
        props.setFilterOptions((prevState) => ({
          ...prevState,
          supplement_type: [
            ...prevState.supplement_type,
            field,
            "30 Day Supplement",
          ],
        }));
      }
    } else if (field === "135 Review Track For 30-Day Notice") {
      if (inType(field)) {
        props.setFilterOptions((prevState) => ({
          ...prevState,
          supplement_type: prevState.supplement_type.filter(
            (item) =>
              item !== field && item !== "135 Day Track For 30 Day Notification"
          ),
        }));
      } else {
        props.setFilterOptions((prevState) => ({
          ...prevState,
          supplement_type: [
            ...prevState.supplement_type,
            field,
            "135 Day Track For 30 Day Notification",
          ],
        }));
      }
    } else {
      if (inType(field)) {
        props.setFilterOptions((prevState) => ({
          ...prevState,
          supplement_type: prevState.supplement_type.filter(
            (item) => item !== field
          ),
        }));
      } else {
        props.setFilterOptions((prevState) => ({
          ...prevState,
          supplement_type: [...prevState.supplement_type, field],
        }));
      }
    }
  };

  const inReason = (field) => {
    return props.filterOptions.supplement_reason.includes(field);
  };

  const reasonFilterUpdate = (field) => {
    if (inReason(field)) {
      props.setFilterOptions((prevState) => ({
        ...prevState,
        supplement_reason: prevState.supplement_reason.filter(
          (item) => item !== field
        ),
      }));
    } else {
      props.setFilterOptions((prevState) => ({
        ...prevState,
        supplement_reason: [...prevState.supplement_reason, field],
      }));
    }
  };

  const masterInFilters = (typeField) => {
    if (typeField === "type") {
      return typeOptions.some((curr) =>
        props.filterOptions.supplement_type.includes(curr[0])
      );
    } else if (typeField === "reason") {
      return reasonOptions.some((curr) =>
        props.filterOptions.supplement_reason.includes(curr[0])
      );
    } else {
      return false;
    }
  };

  const updateFilterOptions = (
    fieldsToUpdate,
    checked,
    optionsKey,
    additlFieldsToUpdate = []
  ) => {
    fieldsToUpdate = [...fieldsToUpdate, ...additlFieldsToUpdate];
    if (checked) {
      const existingFieldsSet = new Set(props.filterOptions[optionsKey]);
      const toAdd = fieldsToUpdate
        .filter(([fieldName]) => !existingFieldsSet.has(fieldName))
        .map(([fieldName]) => fieldName);

      props.setFilterOptions((prevState) => ({
        ...prevState,
        [optionsKey]: [...prevState[optionsKey], ...toAdd],
      }));
    } else {
      const fieldsSet = new Set(fieldsToUpdate.map(([fieldName]) => fieldName));
      const newFields = props.filterOptions[optionsKey].filter(
        (fieldName) => !fieldsSet.has(fieldName)
      );

      props.setFilterOptions((prevState) => ({
        ...prevState,
        [optionsKey]: newFields,
      }));
    }
  };

  const masterFilterUpdate = (checked, typeField) => {
    switch (typeField) {
      case "type":
        updateFilterOptions(
          typeOptions,
          checked,
          "supplement_type",
          additlTypes
        );
        break;
      case "reason":
        updateFilterOptions(reasonOptions, checked, "supplement_reason");
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="card-header">
        <h5>
          <a
            className={"custom-accordion-title d-block py-1"}
            onClick={() => setSupplementFilter(!supplementFilter)}
          >
            Supplement
            <i
              className={
                "mdi accordion-arrow mdi-chevron-" +
                (supplementFilter ? "up" : "down")
              }
            />
          </a>
        </h5>
      </div>
      <div className={"collapse-custom" + (supplementFilter ? " show" : "")}>
        <div className="card-body">
          <div className="form-check form-checkbox-primary mb-2">
            <input
              type="checkbox"
              className="form-check-input"
              checked={masterInFilters("type")}
              onChange={(e) => masterFilterUpdate(e.target.checked, "type")}
            />
            <label className="form-check-label">Type</label>
          </div>
          <Forms
            inFilter={inType}
            filterUpdate={typeFilterUpdate}
            fields={typeOptions}
            type={subForm}
          />
          <div className="form-check form-checkbox-primary mb-2">
            <input
              type="checkbox"
              className="form-check-input"
              checked={masterInFilters("reason")}
              onChange={(e) => masterFilterUpdate(e.target.checked, "reason")}
            />
            <label className="form-check-label">Reason</label>
          </div>
          <Forms
            inFilter={inReason}
            filterUpdate={reasonFilterUpdate}
            fields={reasonOptions}
            type={subForm}
          />
        </div>
      </div>
    </>
  );
}

export default PmaSupplement;
